<app-header
    title="Student facilities"
    subtitle="A welcoming and effective induction is central to ensuring a positive first impressions of you organisation"
></app-header>

<!--<div class="title-banner" id="hots">
    <h1 class="title-banner-text white">IN<span class="green">ROOM</span></h1>
</div>-->
<div class="content-wrapper" style="padding-top: 15px;" id="inspection">
    <h1 class="title inroom-title white">IN<span class="green">SPECTION</span></h1>
    <p style="text-align: center;">A programme to manage and record, area or room, condition inspections of any facilities area.</p>
    <img src="/assets/stems-images/Inspection.png" class="inroom-image" />
</div>
<div class="wrapper-dark">
    <div class="content-wrapper" style="padding-top: 15px;" id="inventory">
        <h1 class="title inroom-title white">IN<span class="green">VENTORY</span></h1>
        <p style="text-align: center;">A programme to facilitate the online completion of student Room Inventory forms by students and to manage the data generated.</p>
        <img src="/assets/stems-images/Inventory.png" class="inroom-image" />
    </div>
</div>
<div class="content-wrapper" style="padding-top: 15px;" id="invoicingDamages">
    <h1 class="title inroom-title white">IN<span class="green">VOICING DAMAGES</span></h1>
    <p style="text-align: center;">A programme for managing the whole procedure for making charges to students for damage to University property. Covering: costing repairs/replacements, communication with students, managing the appeals process in line with the University policy, allocation of costs between parties and invoicing.</p>
</div>

<div style="overflow: hidden;">
    <div class="content-wrapper" id="COVIDcleaning">
        <div class="spacing-element"></div>
        
<div class="case-study-image-wrapper">
                <img src="/assets/images/kleencheck-content-logo.png" class="case-study-cover" style="object-fit: contain;" />
            </div>
<div class="case-study-text-wrapper">
	<h1 class="title">KLEENCHECK</h1>        
	<p>KleenCheck our Cleaning App - Coming Soon <!-- enables you to formalise and manage cleaning routines and professionally communicate your cleaning routine to customers or visitors when they scan your location QR code or visit a reception desk or bar. It also discreetly informs team members when cleaning is due, reminds them of the procedure if necessary, allows them to confirm online that cleaning has been completed and allows you up to the minute access of all cleaning routines in all locations.--> </p>
	<!-- <a [routerLink]="['/cleaning-management']" href="">INSPECTION</a> -->
</div>
    
    <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/stems-images/covid-cleaning.jpg" class="case-study-cover" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">FIRE & MAINTENANCE INSPECTIONS</h3>
                <p class="case-study-content-preview">Bespoke Inspections can be set up locally. Full monitoring and reporting package as with INSPECTION</p>
                <p>For more information on how it can meet your needs - Contact us</p>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>