<app-header
    title="case studies"
    subtitle="These examples of achievements and approaches show, how our range of tools make your process more streamlined."
></app-header>
<div class="wrapper">
    <div class="content-wrapper content-min-height">

        <div class="case-study-wrapper" *ngFor="let study of displayedCaseStudies;">
            <div class="case-study-image-wrapper">
                <img [src]="study.caseStudyImageUrl" class="case-study-image" />
                <img [src]=" '/assets/new-images/case-study-cover-' + study.caseStudyType + '.png' " class="case-study-cover" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">{{ study.title }}</h3>
                <p class="case-study-content-preview">{{ study.contentPreview }}</p>
                <img [src]="study.clientLogoUrl" class="client-logo" />
                <button class="button3" [routerLink]="['/case-study', study.reference]">Read More</button>
            </div>
        </div>

        <div class="centering" *ngIf="pages.length > 1">
            <div class="spacing-element"></div>

            <ng-container *ngFor="let page of pages;">
                <a class="page-anchor" href="" [routerLink]="['/case-studies', 'page-' + page]">
                    <div class="page-button" [ngClass]="{ 'page-button-selected': page == currentPage }">
                        <p class="page-number">{{ page }}</p>
                    </div>
                </a>
            </ng-container>

            <div class="spacing-element"></div>
        </div>

    </div>
</div>
<app-footer></app-footer>