<app-header
    title="BESPOKE"
    subtitle="Here are some examples of bespoke projects we have undertaken"
></app-header>

<div class="bespoke">
    <div class="bespoke-inner">

        <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/new-images/btp.jpg" class="case-study-image" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">Bespoke Training Portal</h3>
                <p class="case-study-content-preview">The Orange platform can be set up to provide a full induction programme followed by an ongoing training resource for all employees</p>
                <!--<img src="/assets/new-images/london-2012-olympics-logo.jpg" class="client-logo" />-->
                <button class="button3" (click)="expand($event);">Read More</button>
                <div class="hidden-content">
                    <h1 class="subtitle">Training portal</h1>
                    <p>
                        The Orange platform can be set up to provide a full induction programme followed by an ongoing training resource for all employees. A
                        range of functionality to manage the employee’s development progress is available including:
                    </p>
                    <ul>
                        <li>Uploading of documents e.g. Passports, driving licences, right to work, qualification certificates. All with expiry dates and a system reminder prior to expiry.</li>
                        <li>Compliance training with email reminders when it has to be renewed.</li>
                        <li>Timed release of development modules.</li>
                        <li>Structured programmes e.g. Bronze, Silver & Gold standards</li>
                        <li>Recording of training activities held outside the platform</li>
                        <li>Booking on to planned internal courses</li>
                        <li>Direct links to training from equipment suppliers or other third party-trainers</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/new-images/2012olympics.jpg" class="case-study-image" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">Meal ordering system for London 2012 Olympics</h3>
                <p class="case-study-content-preview">Security requirements called for all volunteers and contractors' staff to be fed on site</p>
                <img src="/assets/new-images/london-2012-olympics-logo.jpg" class="client-logo" />
                <button class="button3" (click)="expand($event);">Read More</button>
                <div class="hidden-content">
                    <h1 class="subtitle">Meal ordering</h1>
                    <p><b>THE BRIEF</b></p>
                    <p>The high level of security at the Olympic Games in London 2012 resulted in a policy that no water or food could be brought on to Olympic sites by staff or volunteers organising the events. As a result of this the Organising Committee took the decision to provide food for all staff, contractors staff and volunteers at each venue.</p>
                    <p>Apart from the task of providing the food there was a need to understand exactly how many meals would be required for each meal occasion at each venue. Key statistics were:</p>
                    <p>4 meal occasions each day (with extra in Ramadan).<br/>
                        17 main competition venues, some with up to four sub venues.<br/>
            
                        42 non-competition venues<br/>
                        
                        6,700 staff<br/>
                        
                        28,200 Volunteers (on the sites concerned)<br/>
                        
                        34,400 Contractors staff<br/>
                        
                        Catering required from 1st July to 11th September<br/>
                        
                        Data to be communicated to multiple parties.</p>
                    <p><b>THE SOLUTION</b></p>
                    <p>A web based online ordering system allowing each of 50 major contractors, 200 smaller contractors, managers of internal staff and managers of volunteers each to log into their own order areas and place orders for every meal occasion for every day of the operational period. Order holding baskets for each day and rolling confirmation dates gave as much flexibility to change as possible. The numbers of confirmed orders placed were then automatically transferred to the team managing the caterers, to printers to print the meal vouchers required and to the internal invoicing system to invoice the employers concerned for the meals provided.</p>
                    <p>Delivered on time to budget and ran flawlessly through the run up period, the Olympics, Paralympics and the close down period, 2,086,000 meals ordered and delivered.</p>
                </div>
            </div>
        </div>

        <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/new-images/schoolbikes.jpg" class="case-study-image" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">Scheduling Cycling Training and Testing in Schools.</h3>
                <p class="case-study-content-preview">Matching expert availability to School requirements.</p>
                <img src="/assets/new-images/oxfordshirecountycouncil.png" class="client-logo" />
                <button class="button3" (click)="expand($event);">Read More</button>
                <div class="hidden-content">
                    <h1 class="subtitle">Cycle training</h1>
                    <p><b>THE BRIEF</b></p>
                    <p>Oxfordshire County Council has 315 Primary Schools. All Primary Schools in the UK are obliged to run a cycle training programme and to arrange for their pupils to be tested. Schools generally organised their own tutors but the booking of testing for groups of up to 12 pupils with schools across the county all needing to do this at a simiular point in the academic year was a major logistical task.</p>
                    <p><b>THE SOLUTION</b></p>
                    <p>Orange Technology created an online programme where the details of all schools involved were held, where all tutors involved were recorded by the schools and the details of all independent examiners were held including their home location. The system provides for all examiners to record their availablity to carry out tests on a time slot basis. When a school wants a test they log into the system indicating the dates, times and numbers of pupils involved. The system then matches testers to the school needs, automatically emails a tester who can accept or reject the appointment. Once accepted all parties are automatically emailed to confirm dates and times and the testers availability is adjusred accordingly. This project enabled one full time staff member to be allocated to acivities to improve cycle training standards in the county rather than just arranging tests.</p>
                </div>
            </div>
        </div>

        <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/new-images/stadium.jpg" class="case-study-image" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">Benchmarking Hospitality Performance in Stadia.</h3>
                <p class="case-study-content-preview">Data pooled anonymously; performance v average published.</p>
                <img src="/assets/new-images/anon-logo.jpg" class="client-logo" />
                <button class="button3" (click)="expand($event);">Read More</button>
                <div class="hidden-content">
                    <h1 class="subtitle">Benchmarking</h1>
                    <p>An organisation supplying stadia in the UK wished to offer a Benchmarking Service in Hospitality Performance. Orange Technology produced an online system to allow any number of participating stadium operators to submit data which was then pooled and each contributor’s performance analysed against the average of all contributors. A raft of static data was set up e.g. number of seats, number of private boxes, no of bars. number of restaurants and similar. A range of different event types were also included. Contributors submitted data on topics such as attendance levels, food expenditure, drinks expenditure broken down by various categories. A full reporting system also allowed both revenues and costs to be compared.</p>
                </div>
            </div>
        </div>

        <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/new-images/dorset.jpg" class="case-study-image" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">Upward Feedback programme</h3>
                <p class="case-study-content-preview">Replacing a successful paper-based Upward Feedback programme with a fully automated online version</p>
                <img src="/assets/new-images/dorsetcc.png" class="client-logo" />
                <button class="button3" (click)="expand($event);">Read More</button>
                <div class="hidden-content">
                    <h1 class="subtitle">Feedback programme</h1>
                    <p>Dorset County Council had designed a paper based ‘Upward Feedback’ programme which had proved very effective over a number of years. The Council wanted to move the process online retaining all the positive elements whilst at the same reducing the administrative workload of running it. Orange Technology was able to provide a web-based solution which did this and to added a number of substantial enhancements. The Council’s own competency framework was included across a range of management levels with the ability to update all as required. Participants were selected by an organiser then automatically emailed and asked to complete information on line confidentially. Results were made available to moderators if used or directly to participants when not. The programme was further developed into a full 360 feedback programme.</p>
                    <img src="/assets/new-images/performance-overview.jpg" />
                </div>
            </div>
        </div>









        <!--<div class="row">
            <div class="col-lg-4">
                <div class="bespoke-wrapper">
                    <h1 class="title" style="font-size: 1.5rem; line-height: 150%;">The Orange Simulation Company</h1>
                    <p>
                        Industry leading simulation programs for the hospitality, restaurant and tourism industries. We licence our products for
                        educational use and deliver industry training and live events.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="bespoke-wrapper">
                    <h1 class="title">Benchmarking</h1>
                    <p>
                        We are able to provide bespoke benchmarking programmes that allow a group of organisations with similar operations to pool information on set key measures.
                    </p>
                    <p>
                        The system then automatically provides data to each organisation on their performance versus the group average. It is a hosted service and set up so that
                        each member inputs their own data so minimising the costs of running the Benchmarking Exercise.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="bespoke-wrapper">
                    <h1 class="title">Inventory</h1>
                    <p>
                        Our Inventory products provide a complete online item checking system for pre and post occupation.
                    </p>
                    <p>
                        It is a hosted service providing instantaneous electronic delivery. Inventory forms are delivered, together with a notification e-mail to each resident.
                        Forms are completed on-line and instantaneously stored on a secure electronic database.
                    </p>
                    <p>
                        Post occupation Inspection provides:
                    </p>
                    <ul>
                        <li>Enhanced electronic exit inspection features, via a range of hardware options. Tablet PC's, Netbooks, PDA's, (Ipad and Android).</li>
                        <li>Inspection ordering, to guide inspectors around accommodation</li>
                        <li>Electronic comparison of arrival and exit inspection forms</li>
                        <li>The ability to photograph damage and store the photos for further use.</li>
                        <li>Auto uploading of all inspection reports and photographs</li>
                        <li>Ability to add bespoke questions e.g. General Room Condition, Damage, Cleaning issues, Room life issues</li>
                    </ul>
                </div>
            </div>
        </div>-->
    </div>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>