import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'OTL';

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      setTimeout(this.checkForHash.bind(this), 200);
    });
  }

  checkForHash(): void {
    if (window.location.hash) {
      let scrollToId = window.location.hash.replace("#", "");
      if (scrollToId) {
        let ele = document.getElementById(scrollToId);
        if (ele) {
          document.querySelector(window.location.hash).scrollIntoView({
            behavior: 'smooth'
          });
        }
      }
    }
  }



}
