import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-bespoke',
  templateUrl: './bespoke.component.html',
  styleUrls: ['./bespoke.component.scss']
})
export class BespokeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  expand(event): void {
    let wrapper = (event.target as HTMLElement).closest(".case-study-wrapper");
    let hiddenWrapper = (wrapper.querySelector('.hidden-content') as HTMLElement);
    let opening = $(hiddenWrapper).css("display") == "none";
    $(".hidden-content").slideUp();
    if (opening) {
      $(hiddenWrapper).slideToggle();
    }
  }

}
