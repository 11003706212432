<app-header
    title="Induction Functionality"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height" style="padding-bottom: 50px;">
    <h1 class="title">Induction functionality</h1>
    <img class="img" src="/assets/stems-images/InductionFunctionality.png" />

    <!--<app-slideshow height="450px" [zoom]="false" [autoplay]="true" zoomDirection="out">

        <div class="slideshow-item">
            
        </div>
        <div class="slideshow-item">
            <img class="img" src="/assets/stems-images/InductionFunctionality.png" />
        </div>
        <div class="slideshow-item">
            <img class="img" src="/assets/stems-images/Technology.png" />
        </div>

    </app-slideshow>-->


</div>


<app-footer></app-footer>