<app-header
    title="Corporate"
    subtitle="Increase starter productivity as inductions can be completed pre-arrival"
></app-header>

<app-slideshow [height]="(getScreenWidth() > 650) ? '400px': '250px'" backgroundColour="lightgrey" [zoom]="true" [autoplay]="true" zoomDirection="out" (slideChanged)="slideChanged($event);">

    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/1.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">IMPROVE RETENTION LEVELS</p>
            <p class="slide-text">A welcoming and effective induction is central to ensuring a positive first impression of your organisation.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/2.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">READY TO WORK</p>
            <p class="slide-text">Increase starter productivity as inductions can be completed pre-arrival.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/3.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">CLOUD BASED</p>
            <p class="slide-text">All our programmes are hosted on the cloud and available to access anywhere; at home or at work.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/4.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">RESPONSIVE DESIGN</p>
            <p class="slide-text">Compatible on all devices, PC, tablet, mobile phone.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/5.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">CUSTOMER SERVICE & SUPPORT</p>
            <p class="slide-text">We never charge extra for technical support.</p>
        </div>
    </div>

</app-slideshow>

<div class="spacing-element"></div>

<div class="content-wrapper">
    <app-half-image imageUrl="/assets/images/5.jpg" imageSide="right">
        <h1 class="title">Induction & Onboarding</h1>
        <p>
            Our Online Employee Induction service is designed to complement your existing activities by communicating key information through a bespoke online portal, which is designed to precisely meet your needs and requirements.
        </p>
        <p>
            One of the key benefits is that new employees can start their induction process pre-arrival. This promotes a smoother and efficient route that both welcomes the new employee whilst letting them work at their pace, asking questions if necessary, all contributing to getting them settled into their work quickly and being productive as quickly as possible.
        </p>
    </app-half-image>
</div><div class="wrapper-dark"><div class="content-wrapper">
    <app-half-image imageUrl="/assets/images/ascot-screen.jpg" imageSide="left">
        <h1 class="title">Web Based</h1>
        <p>As with all of our products, an Orange online employee induction is provided as a web based hosted service. Which means:</p>
        <ul>
            <li>No installation, capacity or new hardware issues</li>
            <li>No delay, complete programmes set up in weeks</li>
            <li>No serious demands on your IT function</li>
        </ul>
        <p>Starters can access via the internet from anywhere, our Induction sites are compatible with all mobile devices, tablets, laptops and desktop computers. Therefore, they can be accessed by the users at a time that suits them, whether it be on their phone or tablet while they are using public transport, or in the comfort of their own home on a laptop or desktop computer.</p>
        <p>Using an online system allows for an effective transfer of information, which means staff are quickly in a position to feel confident about their new place of work. This process provides them with information, skills and competency that they require quickly and efficiently.</p>
    </app-half-image>
</div></div><div class="content-wrapper">
    <app-half-image imageUrl="/assets/new-images/modular.jpg" imageSide="right">
        <h1 class="title">Personalised</h1>
        <p>Provided in your Corporate style, new starters will see their induction as another part of your existing web-based services. Personalisation of information and timetables tailored to their needs make starters feel welcome and valued.</p>
        <p>Using our Multi-Tagging™ functionality each individual gets an induction programme tailored to their needs. A clear outline of their job/role requirements, showing how the employee fits into their team and how their role fits with the organisation’s strategy and goals, as well as any site-specific information such as facilities and fire drills.</p>
        <p>With our own web-based platform, we offer an approach which enables us to deliver personalised and relevant information to groups of any size, in a way that is easy to follow, consistent for all staff and can be revisited at any time if needed.</p>
    </app-half-image>
</div><div class="wrapper-dark"><div class="content-wrapper">
    <app-half-image imageUrl="/assets/new-images/personalised.jpg" imageSide="left">
        <h1 class="title">Tracking & Reporting</h1>
        <p>The Orange Induction system is fully auditable, staff can be asked to sign online agreements to show that they have read and agree to adhere to these policy statements. This information can then be accessed by management at any time to demonstrate the agreement to compliance.</p>
        <p>Demonstrate a duty of care for employees, with our email Alerts and Knowledge Check tests. Ensure important information is understood, through a series of learning or testing questions. Our full tracking allows the starter, line manager and central functions to monitor progress and chase the new starter if any sections have not been completed.</p>
    </app-half-image>
</div></div><div class="content-wrapper">
    <app-half-image imageUrl="/assets/new-images/tracking-reporting.jpg" imageSide="right">
        <h1 class="title">Modular</h1>
        <p>Our modular approach allows you to build the programme you want at any point in time. New modules can be added, existing modules withdrawn or modified. Modules can also be made “live” at pre-programmed timings. A typical programme would include:</p>
        <ul>
            <li>Meet the organisation</li>
            <li>Key policies</li>
            <li>Relevant working practises</li>
            <li>Your career - training & development</li>
            <li>Health & safety</li>
            <li>Fire safety & evacuation procedures</li>
            <li>Skills assessment (Post appointment)</li>
            <li>Customer service</li>
            <li>Role specific modules e.g. Food handing, Impact on the community</li>
            <li>Equality, diversity and behaviour</li>
            <li>Managing information</li>
            <li>Company specific topics</li>
            <li>Technical training modules</li>
            <li>On-line survey tool</li>
        </ul>
    </app-half-image>
</div><div class="wrapper-dark"><div class="content-wrapper">
    <app-half-image imageUrl="/assets/images/9.jpg" imageSide="left">
        <h1 class="title">Integration</h1>
        <p>A major benefit of our online induction programme is the ease with which it is integrates with wider induction processes in your organisation. Examples of this is incorporating any administrative activities, such as booking onto internal training sessions or gathering data not collected pre-appointment by the use of online forms pre-arrival. Training provided by equipment providers can also be seamlessly integrated.</p>
        <p>Our online induction programmes can work seamlessly with full Workforce Management Systems, smaller Shift Booking Systems (existing or a bespoke solution provided by us). For example, completion of their online induction can trigger the release of an employee’s login information for your Workforce Management System.  Completion of their first shift can automatically release a second stage induction module so development can progress without having to be driven by a line manager.</p>
    </app-half-image>
</div></div>
<div class="spacing-element"></div>

<div style="overflow: hidden;">
    <div class="content-wrapper">
        <div class="spacing-element"></div>
        <h1 class="title">Corporate case studies</h1>
        
        <app-examples caseStudyType="corporate"></app-examples>

    </div>
</div>

<app-footer></app-footer>