<app-header
    title="Construction"
    subtitle="Time spent by the site manager planning and delivering onsite inductions can be used elsewhere more efficiently"
></app-header>

<app-slideshow [height]="(getScreenWidth() > 650) ? '400px': '250px'" [zoom]="false" [autoplay]="true" zoomDirection="out" (slideChanged)="slideChanged($event);">

    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/bricklaying.jpg" />
        <div class="slide-text-wrapper" style="z-index: 16;">
            <p class="slide-title">1. REDUCE SITE MANAGER WORK LOAD</p>
            <p class="slide-text">Time spent by the site manager planning and delivering onsite inductions can be used elsewhere more efficiently.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/details.jpg" />
        <div class="slide-text-wrapper" style="z-index: 16;">
            <p class="slide-title">2. SEAMLESS INDUCTION DELIVERY</p>
            <p class="slide-text">Deliver a uniformed health & safety induction to all employees, contractors & visitors across all sites.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/css-images/Pencils.jpg" />
        <div class="slide-text-wrapper" style="z-index: 16;">
            <p class="slide-title">3. SITE SPECIFIC INFORMATION</p>
            <p class="slide-text">Provide everyone with site specific information, such as site manager, first aider and timings, to help create familiarisation.</p>
        </div>
    </div>

    <div class="construction-video" style="width: 100%; height: 100%; z-index: 15; position: absolute; top: 0; left: 0; overflow: hidden;">
        <video [autoplay]="true" [muted]="true" [loop]="true" style="min-width: 100%; min-height: 100%;" >
            <source src="/assets/tr.mp4" type="video/mp4" #video>
        </video>
    </div>

</app-slideshow>

<div class="spacing-element"></div>

<div class="content-wrapper content-min-height">
    <h1 class="title">How can we help?</h1>
    <p>
        Our Online Site Induction service is designed to complement your existing activities by communicating key information through a bespoke online portal,
        which is designed to suit your needs and requirements. Availability pre-arrival encourages a smoother induction process that both welcomes the new
        employee whilst also getting them settled into work as quickly and productively as possible.
    </p>

    <div class="centering">
        <a (click)="scrollToContact();" style="cursor: pointer;"><app-find-out-more-square
            imageUrl="/assets/images/hammer-icon.jpg"
            title="Request demo"
            content=""
            [linkArray]="['/hospitality']"
            [button]="false"
        ></app-find-out-more-square></a>
        <a target="_blank" [routerLink]="['/assets', 'construction-brochure.pdf']"><app-find-out-more-square
            imageUrl="/assets/images/paint-icon.jpg"
            title="Download brochure"
            content=""
            [linkArray]="['/corporate']"
            [button]="false"
        ></app-find-out-more-square></a>
        <a (click)="scrollToPrices();" style="cursor: pointer;"><app-find-out-more-square
            imageUrl="/assets/images/grips-icon.jpg"
            title="Prices from £500"
            content=""
            [linkArray]="['/construction']"
            [button]="false"
        ></app-find-out-more-square></a>
    </div>
    
</div>
<div class="spacing-element"></div>

<div class="why-choose-us">
    <h1 class="big-title">WHY CHOOSE US</h1>
    <div class="why-choose-inner-wrapper">
        <app-why-choose-us-card
            imageUrl="/assets/new-images/benefits_to_site_manager.jpg"
            title="Benefits to Site Manager"
            [textLines]="['Reduces work load, planning and delivering inductions', 'Reduce risk of litigation due to malpractice']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/seamless_induction_delivery.jpg"
            title="Seamless Induction Delivery"
            [textLines]="['Deliver site specific information to create familiarisation', 'Uniformed health & safety and company inductions across sites']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/your_content.jpg"
            title="Your Content"
            [textLines]="['Company information supplied can be edited by you at any time', 'Interactive opportunities utilising company owned video content']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/increase_reputation_of_company.jpg"
            title="Increase reputation of company"
            [textLines]="['Induction appears bespoke to the individual', 'Full reporting suite ensures all users have understood information']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/suitable_for_visitors_and_contractors.jpg"
            title="Suitable for Visitors & Contractors"
            [textLines]="['Electronic trail of participants progress and test results', 'Upload copies of important ID documents']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/web_based_hosted_service.jpg"
            title="Web Based Hosted Service"
            [textLines]="['No installation issues, with no demands on your existing IT functions', 'No delay, complete programmes set up in weeks']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/instant_online_induction.jpg"
            title="Instant Online Induction"
            [textLines]="['Users can access anywhere with internet at home or on mobile phone', 'Increase site productivity as inductions completed pre-arrival']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/prove_diligence_and_duty_of_care.jpg"
            title="Prove Diligence & Duty of Care"
            [textLines]="['Test knowledge of key information, “pass” to continue', 'Skills assessment can determine eligibility to work']"
        ></app-why-choose-us-card>
        <app-why-choose-us-card
            imageUrl="/assets/new-images/3_pricing_options.jpg"
            title="3 pricing options"
            [textLines]="['Gold, Silver and Bronze price packages available to meet different individual needs and requirements, please contact for more information.']"
        ></app-why-choose-us-card>
    </div>
</div>

<div>
    <div class="centering" style="padding: 40px 0;">
        <iframe src="https://player.vimeo.com/video/293098500" [width]="(getScreenWidth() > 675) ? 640 : 320" [height]="(getScreenWidth() > 675) ? 360 : 180" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
    </div>
</div>

<div id="packages">
    <div class="inner-packages">
        <h2 class="big-title">OUR PACKAGES</h2>
        <div class="row">
            <div class="col-lg-4">
                <app-price-package
                    title="Bronze"
                    colour="#cd7f32"
                    price="500"
                    perWhat="FOR 25 USERS"
                    [boldLines]="[]"
                    [lines]="['Health and Safety', 'Completion Report']"
                ></app-price-package>
            </div>
            <div class="col-lg-4">
                <app-price-package
                    title="Silver"
                    colour="silver"
                    price="750"
                    perWhat="PER SITE"
                    [boldLines]="['As included in Bronze plus:']"
                    [lines]="['Branded in Company Style', 'Good Practice Module', 'Site Map and Information', 'Semi Editable', 'Knowledge Test']"
                ></app-price-package>
            </div>
            <div class="col-lg-4">
                <app-price-package
                    title="Gold"
                    colour="gold"
                    price="1000"
                    perWhat="PER SITE"
                    [boldLines]="['As included in Silver plus:']"
                    [lines]="['Extra Health & Safety', 'Company Information', 'Include Photos / Videos', 'Document Uploader', 'Fully Editable', 'Different version for visitors']"
                ></app-price-package>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>