import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-popup',
  templateUrl: './cookies-popup.component.html',
  styleUrls: ['./cookies-popup.component.scss']
})
export class CookiesPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.checkForCookiesAccepted();
  }

  cookiesAccepted: boolean = false;

  accept(): void {
    this.setCookie("CookieAccepted", "true", 10000);
    this.checkForCookiesAccepted();
  }

  checkForCookiesAccepted(): void {
    let cookie = this.getCookie("CookieAccepted");
    if (cookie) this.cookiesAccepted = true;
  }

  setCookie(name: string, value: string, days: number): void {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  getCookie(name): string {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  eraseCookie(name): void {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

}
