<div class="row">
    <div class="col-md-6" *ngIf="imageSide == 'left' && !imageUrls ">
        <div class="image-wrapper" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
    </div>
    <div class="col-md-6" *ngIf="imageSide == 'left' && imageUrls " style="padding-top: 30px;">

        <app-slideshow height="230px" display="block" width="400px" [zoom]="false" [autoplay]="true" zoomDirection="out">
            <div class="slideshow-item" *ngFor="let image of imageUrls">
                <img class="slide-background" [src]="image" />
            </div>
        </app-slideshow>

    </div>
    <div class="col-md-6">
        <ng-content></ng-content>
    </div>
    <div class="col-md-6" *ngIf="imageSide == 'right' && !imageUrls">
        <div class="image-wrapper" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
    </div>
    <div class="col-md-6" *ngIf="imageSide == 'right' && imageUrls" style="padding-top: 30px;">

        <app-slideshow height="230px" display="block" width="400px" [zoom]="false" [autoplay]="true" zoomDirection="out">
            <div class="slideshow-item" *ngFor="let image of imageUrls">
                <img class="slide-background" [src]="image" />
            </div>
        </app-slideshow>

    </div>
</div>