import { Injectable } from '@angular/core';
import { CaseStudy } from '../models/case-study.class';

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {

  caseStudies: CaseStudy[] = [
    {
      reference: "charging-of-damages",
      title: "Charging of Damages",
      contentPreview: "It has always been The University of Sheffield’s policy to be fully open and to provide students with as much information as practically possible on the breakdown of damage repair costs. Historically this was achieved using a manual process of locating evidence of all costs, emailing them to the student and then manually responding to all queries raised. This became a serious amount of work on the larger claims. Further the University had a comprehensive appeal process to ensure that students were fairly treated, so even when costs were established, responsibility still had to be proved resulting in substantial email dialogues. Information on each case was shared internally by a spreadsheet system which had to be continually updated and circulated. So much internal management time was consumed by the larger charges that many smaller claims simply could not be pursued.",
      content: `<span class='subtitle'>THE SOLUTION</span>
      The solution was to design, together with STEMS, a totally automated system that integrated with the existing STEMS online Room Inventory and Room Inspection products that The University of Sheffield was already using. Pre-programmed costs, an automated communication system embracing the communication of costs and the managing the full student appeal process, the use of photographic evidence gathered by University Inspectors using the STEMS Room Inspection package, the allocation of costs between parties when appropriate, were all key elements of the specification.
      <span class='subtitle'>THE BENEFITS AFTER IMPLEMENTATION</span>
      <ul><li>Significant saving in administration.</li><li>Speedier response to residents, so claims were made much closer to the to the event.</li><li>Provided a one stop shop for students enforcing the importance of completing their on-line Room Inventory reports accurately and comprehensively.</li><li>The availability to students of photographs online reduced disputes and response times.</li><li>A full online report suite enabled better and wider communication and a reduction in admin costs.</li><li>The ability to analyse trends not only in damage and the location of damage but also in the costs of damage and the failure rate of equipment and fittings.</li><li>The University had a fully auditable process covering every damage claim providing up to date information at any time.</ul>
      For further information of how we can help you with damages charging or any other room management issue <a href="" [routerLink]="['/student-communication']" fragment="contact">CONTACT US</a>.`,
      caseStudyImageUrl: "/assets/images/dn-screen.jpg",
      clientLogoUrl: "/assets/images/sheffield.jpg",
      caseStudyType: "corporate"
    },
    {
      reference: "aberystwyth",
      title: "Dual Language",
      contentPreview: "Aberystwyth University opened its Department of Welsh Language and Literature in 1875. Since then, it has built a commendable record, for not only teaching in Welsh, but also for supporting bilingualism in the workplace. Active support is provided to colleagues in the University to use and develop their Welsh language skills. Named Welsh University of the year in 2020 by the Sunday Times, it is one of the main providers of Higher Education in Welsh and delivers undergraduate and postgraduate courses through the medium of Welsh and bilingually. It is unique in having a totally Welsh speaking Hall of Residence described as the beating heart of Aberystwyth University's vibrant Welsh speaking community. Consequently, all University operations which involve communication with students need to be provided in a bilingual format.",
      content: `Aberystwyth University opened its Department of Welsh Language and Literature in 1875. Since then, it has built a commendable record, for not only teaching in Welsh, but also for supporting bilingualism in the workplace. Active support is provided to colleagues in the University to use and develop their Welsh language skills. Named Welsh University of the year in 2020 by the Sunday Times, it is one of the main providers of Higher Education in Welsh and delivers undergraduate and postgraduate courses through the medium of Welsh and bilingually. It is unique in having a totally Welsh speaking Hall of Residence described as the beating heart of Aberystwyth University's vibrant Welsh speaking community. Consequently, all University operations which involve communication with students need to be provided in a bilingual format.
      <span class='subtitle'>THE SOLUTION</span>
      Aberystwyth University has utilised the STEMS dual language facility for its student online pre arrival induction programme and its Room Inventory and Room Inspection programmes.
      The student induction programme whilst still retaining all the functionality of the programme is delivered in Welsh and English with the ability to switch between the two languages instantly on any page. The STEMS Room Inventory and Room Inspection products also enable all the software generated messages to be available in both languages.
      The STEMS dual language facility can be utilised in any language, for further information <a href="" [routerLink]="['/student-communication']" fragment="contact">CONTACT US</a>`,
      caseStudyImageUrl: "/assets/images/ascot-screen.jpg",
      clientLogoUrl: "/assets/stems-images/aberystwyth.jpg",
      caseStudyType: "corporate"
    },
    {
      reference: "university-of-sheffield",
      title: "Staff Induction at the University of Sheffield",
      contentPreview: "The University of Sheffield employs a range of staff from cleaner to professor, across 51 academic and 15 professional services departments. Howver, despite a strong employer brand from a recruitment perspective, it felt it was missing an opportunity to demonstrate its goals and values during induction.",
      content: `The University of Sheffield employs a range of staff from cleaner to professor, across 51 academic and 15 professional services departments. However, despite a strong employer brand from a recruitment perspective, it felt it was missing an opportunity to demonstrate its goals and values during induction. This was made more complex thanks to the fact that 250 staff across the institution are responsible for induction, many of whom were unaware of the resources available to support new starters.
      In partnership with our sister company STEMS by RP we developed a branded induction portal for all new staff regardless of role at the university. Since implementation of the staff induction portal the University of Sheffield has found that the number of staff resigning within three months of joining has dropped by 36% with managers stating that the induction programme enabled them to provide a more personal welcoming experience.`,
      caseStudyImageUrl: "/assets/images/sheffield-screen.jpg",
      clientLogoUrl: "/assets/images/sheffield.jpg",
      caseStudyType: "corporate"
    }
  ];

}
