import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from 'src/app/services/case-studies.service';

@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styleUrls: ['./case-study.component.scss']
})
export class CaseStudyComponent implements OnInit {

  constructor(public caseStudyService: CaseStudiesService, public route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(this.handleRoute.bind(this));
  }

  ngOnInit(): void {
  }

  handleRoute(params: { caseStudyReference: string }): void {
    this.caseStudy = this.caseStudyService.caseStudies.find(c => c.reference == params.caseStudyReference);
    if (!this.caseStudy) {
      console.error("Unrecognised case study reference: " + params.caseStudyReference);
      this.router.navigate(["/case-studies"]);
    }
  }

  caseStudy: CaseStudy;

  formatContent(content: string): string {
    return content.split("\n").join("</p><p class='case-study-content'>");
  }

}
