import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
  selector: 'app-header-breadcrumbs',
  templateUrl: './header-breadcrumbs.component.html',
  styleUrls: ['./header-breadcrumbs.component.scss']
})
export class HeaderBreadcrumbsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.url.subscribe(this.setCurrentPath.bind(this));
  }

  breadcrumbs: { name: string; link: string[] }[] = [];

  setCurrentPath(url: UrlSegment[]): void {
    this.breadcrumbs = [];
    for (let i=0; i < url.length; i++) {
      let urlSegment = url[i];
      let segment = urlSegment.path;
      let segmentSections = segment.split("-");
      for (let i=0; i < segmentSections.length; i++) {
        segmentSections[i] = segmentSections[i].charAt(0).toUpperCase() + segmentSections[i].slice(1);
      }
      let breadCrumbText = segmentSections.join(" ");
      if (breadCrumbText == "Case Study") breadCrumbText = "Case Studies"; // Hard coded rule to make breadcrumbs work properly on individual case study page
      let linkArray: string[] = [];
      for (let j=0; j <= i; j++) {
        if (j == 0) linkArray.push('/' + url[j].path);
        else linkArray.push(url[j].path);
      }
      let breadCrumb: { name: string; link: string[] } = { name: breadCrumbText, link: linkArray }
      this.breadcrumbs.push(breadCrumb);
    }
  }

}
