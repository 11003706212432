import { Component, ElementRef, Input, OnInit, Renderer2, EventEmitter, Output } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  /* NOTES
  * If you want custom slide backgrounds, put an image in to each slideshow-item and give it the class slide-background
  */

  // Input values
  @Input() height: string;
  @Input() width: string;
  @Input() display: string;
  @Input() backgroundColour: string;
  @Input() zoom: boolean;
  @Input() zoomDirection: string; // can be: up*, down*, left*, right*, in or out                   * = TODO
  @Input() autoplay: boolean;
  @Output() slideChanged = new EventEmitter();

  // Default values for inputs
  defaultHeight: string = "400px";
  defaultWidth: string = "100%";
  defaultDisplay: string = "block";
  defaultBackgroundColour: string = "transparent";
  defaultZoom: boolean = false;
  defaultZoomDirection = "out";
  defaultAutoPlay = false;

  // Other attributes required
  elements: any = [];
  currentIndex: number = 0;
  previousIndex: number = 0;
  backgroundsUsed: boolean = false;
  backgroundImageUrls: string[] = [];
  autoslideTimeoutHandle: any = null;
  zoomTimeoutHandle: any = null;
  zoomingBackgroundSize: string = "120%";
  autoslideInterval: number = 10000;
  sizePercent: number = 0;
  dots: number[] = [];

  constructor(private elem: ElementRef) { }

  ngOnInit(): void {
    // Set the default value for any unset inputs
    if (this.height == undefined) this.height = this.defaultHeight;
    if (this.width == undefined) this.width = this.defaultWidth;
    if (this.display == undefined) this.display = this.defaultDisplay;
    if (this.backgroundColour == undefined) this.backgroundColour = this.defaultBackgroundColour;
    if (this.zoom == undefined) this.zoom = this.defaultZoom;
    if (this.zoomDirection == undefined) this.zoomDirection = this.defaultZoomDirection;
    if (this.autoplay == undefined) this.autoplay = this.defaultAutoPlay;
  }

  ngAfterViewInit(){
    // Force styles on all of the slideshow-item elements
    this.elements = this.elem.nativeElement.querySelectorAll('.slideshow-item');
    this.elements.forEach(ele => {
      ele.style.position = "absolute";
      ele.style.top = "0";
      ele.style.left = "0";
      ele.style.height = "100%";
      ele.style.width = "100%";
      if (this.zoom) ele.style.backgroundSize = "120% auto";
      else ele.style.backgroundSize = "cover";
      ele.style.backgroundPosition = "50% 50%";
    })
    this.checkForSlideBackgrounds()
    if (this.backgroundsUsed) { 
      this.setSlideBackgroundUrlArray();
      this.hideBackgroundImages();
      this.setBackgroundImages();
    }
    this.setInitialSlides();
    setTimeout(this.setDots.bind(this), 200);
    if (this.autoplay) this.autoslideTimeoutHandle = setInterval(this.iterateSlide.bind(this), this.autoslideInterval);
  }

  resetTimers(): void {
    clearInterval(this.autoslideTimeoutHandle);
    if (this.autoplay) this.autoslideTimeoutHandle = setInterval(this.iterateSlide.bind(this), this.autoslideInterval);
  }

  setDots(): void {
    this.dots = [];
    for (let i = 0; i < this.elements.length; i++) {
      this.dots.push(i);
    }
  }

  dotClicked(dot: number): void {
    this.currentIndex = dot;
    this.setSlide();
    this.resetTimers();
  }

  setSlideBackgroundUrlArray(): void {
    if (this.backgroundsUsed) this.elements.forEach(ele => {
      if ($(ele).find(".slide-background").length > 0) this.backgroundImageUrls.push(($(ele).find(".slide-background")[0].src));
      else this.backgroundImageUrls.push("");
    });
  }

  checkForSlideBackgrounds(): void {
    this.elements.forEach(ele => {
      if ($(ele).find(".slide-background").length > 0) this.backgroundsUsed = true;
    })
  }

  setInitialSlides(): void {
    this.elements.forEach(ele => {
      ele.style.opacity = 0;
    });
    this.elements[0].style.opacity = 1;
    setTimeout(function() { this.elements.forEach(ele => ele.style.transition = "opacity .8s ease") }.bind(this), 200); // set transitions after loading
    if (this.zoom) this.zoomSlide();
    this.slideChanged.emit(this.currentIndex);
  }

  hideBackgroundImages(): void {
    this.elements.forEach(ele => {
      if ($(ele).find(".slide-background").length > 0) $($(ele).find(".slide-background")[0]).fadeOut(0);
    })
  }

  setBackgroundImages(): void {
    for (let i=0; i < this.elements.length; i++) {
      let ele = this.elements[i];
      let url = this.backgroundImageUrls[i];
      ele.style.backgroundImage = `url(${url})`;
    }
  }

  iterateSlide(): void {
    this.currentIndex++;
    this.setSlide();
  }

  arrowChangeSlide(changeBy: number): void {
    this.currentIndex += changeBy;
    this.setSlide();
    this.resetTimers();
  }

  validateCurrentIndex(): void {
    if (this.currentIndex > this.elements.length - 1) this.currentIndex = 0;
    if (this.currentIndex < 0) this.currentIndex = this.elements.length - 1;
  }

  setSlide(): void {
    this.validateCurrentIndex();
    this.slideChanged.emit(this.currentIndex);
    this.elements.forEach(ele => {
      ele.style.opacity = 0;
    });
    this.elements[this.currentIndex].style.opacity = 1;
    if (this.zoom) this.handleZooming();

    // Set this for the next iteration
    this.previousIndex = this.currentIndex;
  }

  handleZooming(): void {
    for (let i=0; i < this.elements.length; i++) {
      if (i !== this.previousIndex) this.resetBackgroundPosition(this.elements[i]);
    }
    this.zoomSlide();
  }

  resetBackgroundPosition(ele: any): void {
    if (this.zoom) {
      ele.style.backgroundSize = "120% auto";
      if (this.zoomDirection == "up") ele.style.backgroundPosition = "50% 0%";
      if (this.zoomDirection == "down") ele.style.backgroundPosition = "50% 100%";
      if (this.zoomDirection == "left") ele.style.backgroundPosition = "0% 50%";
      if (this.zoomDirection == "right") ele.style.backgroundPosition = "100% 50%";
      if (this.zoomDirection == "in") ele.style.backgroundPosition = "100% auto";
    }
  }

  zoomSlide(): void {
    let ele = this.elements[this.currentIndex];
    clearInterval(this.zoomTimeoutHandle);
    if (this.zoomDirection == "up") { this.zoomTimeoutHandle = setInterval(this.handleZoomUp.bind(this), this.calculateZoomTime()) };
    if (this.zoomDirection == "down") { this.zoomTimeoutHandle = setInterval(this.handleZoomDown.bind(this), this.calculateZoomTime()) };
    if (this.zoomDirection == "left") this.zoomTimeoutHandle = setInterval(this.handleZoomLeft.bind(this), this.calculateZoomTime());
    if (this.zoomDirection == "right") this.zoomTimeoutHandle = setInterval(this.handleZoomRight.bind(this), this.calculateZoomTime());
    if (this.zoomDirection == "in") { this.sizePercent = 100; this.zoomTimeoutHandle = setInterval(this.handleZoomIn.bind(this), this.calculateZoomTime()); }
    if (this.zoomDirection == "out") { this.sizePercent = 120; this.zoomTimeoutHandle = setInterval(this.handleZoomOut.bind(this), this.calculateZoomTime()); }
  }

  calculateZoomTime(): number {
    let percentToMove = 50;
    if (this.zoomDirection == "in" || this.zoomDirection == "out") percentToMove = 20;
    let movesPerPercent = 10;
    let framesRequired = percentToMove * movesPerPercent;
    let secondsToMove = this.autoslideInterval / 1000;
    let fps = framesRequired / secondsToMove;
    return 1000 / fps;
  }

  handleZoomUp(): void {
    // TODO
  }

  handleZoomDown(): void {
    // TODO
  }

  handleZoomLeft(): void {
    // TODO
  }

  handleZoomRight(): void {
    // TODO
  }

  handleZoomIn(): void {
    this.sizePercent += 0.1;
    this.elements[this.currentIndex].style.backgroundSize = `${this.sizePercent}% auto`;
  }

  handleZoomOut(): void {
    this.sizePercent -= 0.1;
    this.elements[this.currentIndex].style.backgroundSize = `${this.sizePercent}% auto`;
  }


}
