<app-header
    title="STUDENT COMMUNICATION"
    subtitle="A  welcoming and fully personalised student induction is central to creating a positive living and learning environment."
></app-header>

<app-slideshow [height]="(getScreenWidth() > 650) ? '400px': '250px'" backgroundColour="lightgrey" [zoom]="true" [autoplay]="true" zoomDirection="out" (slideChanged)="slideChanged($event);">

    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/1.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">IMPROVE RETENTION LEVELS</p>
            <p class="slide-text">A comprehensive personalised pre-arrival Induction, together with clear communications post arrival improve the student experience</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/2.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">READY TO STUDY</p>
            <p class="slide-text">Fully personalised Induction gives and gathers much information pre-arrival.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/3.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">CLOUD BASED</p>
            <p class="slide-text">All our programmes are hosted on the cloud and available to access from anywhere.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/4.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">RESPONSIVE DESIGN</p>
            <p class="slide-text">Compatible on all devices, PC, tablet, mobile phone.</p>
        </div>
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/images/5.jpg" />
        <div class="slide-text-wrapper">
            <p class="slide-title">CUSTOMER SERVICE & SUPPORT</p>
            <p class="slide-text">We never charge extra for technical support.</p>
        </div>
    </div>

</app-slideshow>

<div class="spacing-element"></div>

<!--<div class="content-wrapper content-min-height">
    <h1 class="title">Our services</h1>
    <p>
        Our services accompany employees throughout their journey within a workplace. From onboarding via a pre-arrival induction and selection testing, the first month of transitioning into their new role, onto continuous development and training using our simulations and 360 feedback tool.
    </p>
    <p>
        Delivering information to large numbers of people that is tailored to the individual at a time that is convenient for the user, whilst also gathering information in a level of interactivity that is only available via an online service.
    </p>
    <p>
        By identifying customer needs we have a flexibility to adapt our existing services to need those needs or to provide a bespoke solution.
    </p>
</div>-->
<div class="content-wrapper">
    <app-half-image imageUrl="/assets/stems-images/living-and-learning-portal.jpg" imageSide="right">
        <h1 class="title">Living & Learning Portal</h1>
        <p>
            The Living & Learning Portal combines all the functionality of the Induction Platform, UNI-Fi and the Quick Portal (detailed later) into one comprehensive communications package. Any number of induction types can be included with full content management services. Content for Uni-Fi is managed by University staff.
        </p>
        
    </app-half-image>
</div><div class="wrapper-dark"><div class="content-wrapper" id="inductionprogrammes">
    <app-half-image imageUrl="/assets/stems-images/student.jpg" imageSide="left">
        <h1 class="title">Induction Platform</h1>
        <p>All inductions in University corporate style with a corporate University overview where relevant. Any number or any combination can be utilised</p>
        <ul>
            <li>Faculty Induction</li>
            <li>Student Support Services Induction (*Free inclusion of RAVI see later.)</li>
            <li>International Students Induction</li>
            <li>Student Union Induction</li>
            <li>Library Induction</li>
            <li>Health & Safety Induction</li>
            <li>Equality & Diversity Induction</li>
            <li>Sustainability Induction</li>
            <li>Subject Induction</li>
            <li>Student Studying Skills Assessment</li>
        </ul>
        <div class="button-wrapper">
            <div>
                <a href="" [routerLink]="['/induction-benefits']"><button class="button2">Induction Benefits</button></a>
                <a href="" [routerLink]="['/induction-functionality']"><button class="button2">Induction Functionality</button></a>
                <a href="" [routerLink]="['/technology']"><button class="button2">Technology</button></a>
            </div>
            <p style="margin-bottom: 5px;">Some examples of content: </p>
            <a href="" [routerLink]="['/faculty-induction']"><button class="button2">Faculty Induction</button></a>
            <a href="" [routerLink]="['/library-induction']"><button class="button2">Library Induction</button></a>
            <a href="" [routerLink]="['/international-student-induction']"><button class="button2">International Student Induction</button></a>
            <a href="" [routerLink]="['/skills-self-assessment']"><button class="button2">Skills self-assessment for students</button></a>
        </div>
    </app-half-image>
</div></div><div class="content-wrapper">
    <app-half-image [imageUrls]="['/assets/stems-images/vulnerability1.jpg', '/assets/stems-images/vulnerability2.jpg', '/assets/stems-images/vulnerability3.jpg']" imageSide="right">
        <h1 class="title">RAVI</h1>
        <p>An experimental concept to enable students to assess themselves on vulnerability and resilience without anyone else being involved and be directed to resources in light of the indications they receive from the assessment. No information is passed to the University unless the student chooses to make contact.</p>
        <p>A summary of a Resilience and Vulnerability in matrix form is presented.</p>
        <p>A simple online questionnaire produces a summary profile showing which way the student is ‘tilting’ and points them to activities to bring them to a more even balance</p>
    </app-half-image>
</div>
<div class="content-wrapper" id="quickportal">
    <app-half-image imageUrl="/assets/stems-images/quick-portal.jpg" imageSide="left">
        <h1 class="title">QUICK PORTAL</h1>
        <p>A QUICK PORTAL is designed to provide Universities which don’t have a STEMS induction programme with the facility to utilise STEMS MultiTagging™ and send targeted messages to a variety of different student groups. This includes specific groups created and virtual groups which can be a combination of any number of specific groups.</p>
        <p>It provides a single notice board for messages and provides the facility to email the groups concerned as well as displaying the targeted online messages.</p>
        <p>The basic version, which allows for the University logo to be included costs £1,995 + Vat for a one-year licence including hosting and can be set up within 10 days of receipt of a Purchase Order. Full University branding is available for costs and timing - contact us.</p>
    </app-half-image>
</div>


<div class="title-banner" id="hots">
    <h1 class="title-banner-text white">IN<span class="green">ROOM</span></h1>
</div>
<div class="content-wrapper" style="padding-top: 15px;" id="inspection">
    <h1 class="title inroom-title white">IN<span class="green">SPECTION</span></h1>
    <p>A programme to manage and record, area or room, condition inspections of any facilities area.</p>
    <img src="/assets/stems-images/Inspection.png" class="inroom-image" />
</div>
<div class="wrapper-dark">
    <div class="content-wrapper" style="padding-top: 15px;" id="inventory">
        <h1 class="title inroom-title white">IN<span class="green">VENTORY</span></h1>
        <p>A programme to facilitate the online completion of student Room Inventory forms by students and to manage the data generated.</p>
        <img src="/assets/stems-images/Inventory.png" class="inroom-image" />
    </div>
</div>
<div class="content-wrapper" style="padding-top: 15px;" id="invoicingDamages">
    <h1 class="title inroom-title white">IN<span class="green">VOICING DAMAGES</span></h1>
    <p>A programme for managing the whole procedure for making charges to students for damage to University property. Covering: costing repairs/replacements, communication with students, managing the appeals process in line with the University policy, allocation of costs between parties and invoicing.</p>
</div>

<div style="overflow: hidden;">
    <div class="content-wrapper" id="COVIDcleaning">
        <div class="spacing-element"></div>
        
<div class="case-study-image-wrapper">
                <img src="/assets/images/kleencheck-content-logo.png" class="case-study-cover" style="object-fit: contain;" />
            </div>
<div class="case-study-text-wrapper">
	<h1 class="title">KLEENCHECK</h1>        
	<p>KleenCheck our Cleaning App - Coming Soon <!-- enables you to formalise and manage cleaning routines and professionally communicate your cleaning routine to customers or visitors when they scan your location QR code or visit a reception desk or bar. It also discreetly informs team members when cleaning is due, reminds them of the procedure if necessary, allows them to confirm online that cleaning has been completed and allows you up to the minute access of all cleaning routines in all locations.--> </p>
	<!-- <a [routerLink]="['/cleaning-management']" href="">INSPECTION</a> -->
</div>
    
    <div class="case-study-wrapper">
            <div class="case-study-image-wrapper">
                <img src="/assets/stems-images/covid-cleaning.jpg" class="case-study-cover" />
            </div>
            <div class="case-study-text-wrapper">
                <h3 class="title">FIRE & MAINTENANCE INSPECTIONS</h3>
                <p class="case-study-content-preview">Bespoke Inspections can be set up locally. Full monitoring and reporting package as with INSPECTION</p>
                <p>For more information on how it can meet your needs - Contact us</p>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>