<app-header
    title="case study"
    subtitle="Over 1,000,000 people have been inducted using our platform in the UK"
></app-header>
<div class="wrapper">
    <div class="content-wrapper content-min-height" *ngIf="caseStudy">

        <h1 class="title">{{ caseStudy.title }}</h1>
        <div class="case-study-image-wrapper">
            <img [src]="caseStudy.caseStudyImageUrl" class="case-study-image" />
            <img [src]=" '/assets/new-images/case-study-cover-' + caseStudy.caseStudyType + '.png' " class="case-study-cover" />
        </div>
        <p class="case-study-content" [innerHTML]="formatContent(caseStudy.content)"></p>

    </div>
</div>
<app-footer></app-footer>