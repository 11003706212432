import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from '../../services/case-studies.service';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {

  constructor(public caseStudyService: CaseStudiesService, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(this.handleRoute.bind(this));
  }

  displayedCaseStudies: CaseStudy[] = [];
  currentPage: number = 1;
  pages: number[] = [];
  studiesPerPage: number = 4;

  handleRoute(params: { page: string }): void {
    if (params.page) {
      this.currentPage = parseInt(params.page.replace("page-", ""));
    } else {
      this.currentPage = 1;
    }
    this.displayedCaseStudies = this.caseStudyService.caseStudies.slice( (this.currentPage - 1) * this.studiesPerPage , this.currentPage * this.studiesPerPage );
    let pagesRequired = Math.ceil( this.caseStudyService.caseStudies.length / this.studiesPerPage );
    this.pages = [];
    if (pagesRequired > 1) {
      for (let i=1; i <= pagesRequired; i++) {
        this.pages.push(i);
      }
    }
  }

}
