<div class="wrapper">
    <div class="content-wrapper">
        <h1 class="title">Products</h1>
        <p>Find out more information on our products</p>
        
        <!-- DOCUMENTATION: https://github.com/ivylaboratory/angular-carousel -->
        <div class="carousel-wrapper">
            <carousel
                [loop]="true"
                [height]="100"
                [cellsToShow]="(screenWidth > 1100) ? 5 : (screenWidth > 800) ? 3 : 2"
                [overflowCellsLimit]="20"
                [margin]="10"
                [transitionDuration]="300"
                [borderRadius]="0"
                [arrows]="true"
                [arrowsOutside]="true"
                [dots]="false"
                arrowsTheme="light"
            >
                <!-- HACK: These cells are repeated for a few times because when there was too few the carousel would fail to loop properly -->
                <ng-container *ngFor="let i of sliderLoopArray;">

                    <div *ngFor="let link of links" class="carousel-cell" (mousedown)="caseStudyClicked($event, link.redirectArray, true, link.fragment);" (mouseup)="caseStudyClicked($event, link.redirectArray, false, link.fragment);">
                        <div class="vertial-center-wrapper">
                            <img [src]="'/assets/icons/' + link.iconUrl" class="carousel-image" draggable="false" />
                            <p>{{ link.name }}</p>
                        </div>
                    </div>

                    <!--<div *ngFor="let link of links" class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/induction-benefits'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'benchmarking'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/availability-booker-icon.png" class="carousel-image" draggable="false" />
                            <p>Living Induction</p>
                        </div>
                    </div>

                    <div class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/student-communication', 'induction-programmes'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'cleaning-management-and-monitoring'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/communications-portal-icon.png" class="carousel-image" draggable="false" />
                            <p>Faculty induction</p>
                        </div>
                    </div>
                    <div class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/tools', 'employee-relationship-gateway'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'employee-relationship-gateway'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/juice-icon.png" class="carousel-image" draggable="false" />
                            <p>Employee Relationship Gateway</p>
                        </div>
                    </div>
                    <div class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/tools', 'juice'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'juice'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/communications-portal-icon.png" class="carousel-image" draggable="false" />
                            <p>Juice</p>
                        </div>
                    </div>
                    <div class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/tools', 'three-sixty-feedback'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'three-sixty-feedback'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/three-sixty-feedback-icon.png" class="carousel-image" draggable="false" />
                            <p>360 Feedback</p>
                        </div>
                    </div>
                    <div class="carousel-cell" (mousedown)="caseStudyClicked($event, ['/tools', 'business-simulations'], true);" (mouseup)="caseStudyClicked($event, ['/tools', 'business-simulations'], false);">
                        <div class="vertial-center-wrapper">
                            <img src="/assets/new-images/juice-icon.png" class="carousel-image" draggable="false" />
                            <p>Business Simulations</p>
                        </div>
                    </div>-->
                </ng-container>
            </carousel>
        </div>


    </div>
</div>
