import { Component, ElementRef, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from 'src/app/services/case-studies.service';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit {

  constructor(private elem: ElementRef, private caseStudyService: CaseStudiesService) {
    window.onresize = this.setScreenWidth.bind(this);
  }

  ngOnInit(): void {
    this.setScreenWidth();
  }

  displayedCaseStudies: CaseStudy[] = [];

  ngAfterViewInit(): void {
    this.slideTextWrappers = this.elem.nativeElement.querySelectorAll('.slide-text-wrapper');
  }

  screenWidth: number;
  slideHandle: number = null;
  slideTextWrappers: any[] = [];

  setScreenWidth(): void {
    this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  slideChanged(index: number) {
    clearTimeout(this.slideHandle);
    this.slideHandle = setTimeout(this.showText.bind(this), 1000, index);
  }

  showText(index: number): void {
    this.slideTextWrappers.forEach(ele => ele.style.display = "none");
    $(this.slideTextWrappers[index]).slideDown(1000);
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

}
