<div class="row-wrapper">

    <div class="case-study-wrapper" *ngFor="let study of displayedCaseStudies;">
        <div class="case-study-image-wrapper">
            <img [src]="study.caseStudyImageUrl" class="case-study-image" />
            <img [src]=" '/assets/new-images/case-study-cover-' + study.caseStudyType + '.png' " class="case-study-cover" />
        </div>
        <div class="case-study-text-wrapper">
            <h3 class="title">{{ study.title }}</h3>
            <p class="case-study-content-preview">{{ study.contentPreview }}</p>
            <img [src]="study.clientLogoUrl" class="client-logo" />
            <button class="button3" [routerLink]="['/case-study', study.reference]">Read More</button>
        </div>
    </div>

</div>