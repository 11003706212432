import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Set event listeners for dropdown menus
    $(".dropdown-nav-anchor").hover(function(ele) {
      $(ele.target).find(".nav-dropdown").slideDown(200);
    }, function() {
      $(".nav-dropdown").slideUp(200);
    });
    $(".mobile-dropdown-nav-anchor").hover(function(ele) {
      $(".mobile-nav-dropdown").slideDown(200);
    }, function() {
      $(".mobile-nav-dropdown").slideUp(200);
    });
    $("body").click(this.bodyClicked.bind(this));
  }

  bodyClicked(event: any): void {
    // Check if the click was not part of the mobile dropdown
    if($(event.target).closest(".nav-wrapper-mobile").length === 0) {
      $(".mobile-dropdown").slideUp(200);
    }
  }

  openMobileDropdown(): void {
    $(".mobile-dropdown").slideDown(200);
  }

}
