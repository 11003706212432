<app-header
    title="HOW IT WORKS"
    subtitle="Continually developing Innovative Solutions for Higher Education since 2007"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">How it works</h1>
    <p>
        We have described on this site the products and services we have available for the Higher Education sector. Every product we have developed has come either from someone in the sector telling us of a problem or from us recognising a need which someone working in a University has subsequently confirmed as an issue that needs addressing. Every product started out dealing with the most important aspect of the issue concerned but then has grown and grown.
    </p>
    <p>
        So it starts with you telling us of your needs...
    </p>
    <p>
        Most new projects include a bespoke element, whether that be new or a development of one of our existing products, we therefore always welcome a no commitment initial discussion of the issue you are confronting.
    </p>
    <p>
        All of our services are provided as fully hosted solutions.
    </p>
    <p>
        If you have a need, no matter how big or small, how complex or simple, how novel or entrenched – Contact us.
    </p>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>