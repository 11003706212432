<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <div class="row">
        <div class="col-lg-6">
            <h1 class="title">Juice</h1>
            <p>
                Unique to your company, you can personalise your section headings, include your company logo and link to any of your social media profiles
                for seamless communication, you can also take full advantage of our Multi-Tagging functionality within Juice in which users can be assigned
                to certain groups, therefore only seeing articles relevant to them.
            </p>
            <p>
                The content management system for admins is designed to allow you to upload, schedule and publish articles quickly and effectively. Upload your
                own images, video, text and advertisements.
            </p>
            <p>
                Your Juice is unique to your organisation, you create the articles, you can personalise your section headings, include your company logo and use
                your own photos and videos.
            </p>
            <p>Request a demo using contact us below</p>
        </div>
        <div class="col-lg-6">
            <img src="/assets/new-images/juice.jpg" class="feedback-image" />
        </div>
    </div>

</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>