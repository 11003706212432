<app-header
    title="Faculty Induction"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Faculty Induction</h1>
    <img class="img" src="/assets/stems-images/Faculty1.jpg" />
    <img class="img" src="/assets/stems-images/Faculty2.jpg" />
    <p style="margin-top: 30px;">For more information on Faculty Induction <a href="" [routerLink]="['/student-communication']" fragment="contact">CONTACT US</a></p>
    <a href="" [routerLink]="['/student-communication']" fragment="inductionprogrammes"><p>See general information on Student Induction</p></a>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>