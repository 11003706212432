import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-tools',
  templateUrl: './our-tools.component.html',
  styleUrls: ['./our-tools.component.scss']
})
export class OurToolsComponent implements OnInit {

  constructor(private router: Router) {
    window.onresize = this.setScreenWidth.bind(this);
  }

  ngOnInit(): void {
    this.setScreenWidth();
  }

  // This is used to repeat the elements in the slider
  sliderLoopArray: number[] = Array(8).fill(0);

  screenWidth: number;
  mouseDownX: number = null;

  setScreenWidth(): void {
    this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  caseStudyClicked(event: any, linkArray: string[], mouseDown: boolean, fragment: string): void {
    if (mouseDown) {
      this.mouseDownX = event.pageX;
    } else {
      let dragDistance = Math.abs(this.mouseDownX - event.pageX);
      if (dragDistance <= 10) this.router.navigate(linkArray, { fragment: fragment });
    }
  }

  links: { name: string, iconUrl: string, redirectArray: string[], fragment: string }[] = [
    { name: "COVID Cleaning", iconUrl: "cleaning-icon.png", redirectArray: ["/student-communication"], fragment: "COVIDcleaning" },
    { name: "Damages", iconUrl: "damages-icon.png", redirectArray: ["/damages"], fragment: "" },
    { name: "Faculty Induction", iconUrl: "faculty-induction-icon.png", redirectArray: ["/faculty-induction"], fragment: "" },
    { name: "Fire, H&S, Maintenance", iconUrl: "h-and-s-icon.png", redirectArray: ["/student-communication"], fragment: "inspection" },
    { name: "Inspections", iconUrl: "inspection-icon.png", redirectArray: ["/student-communication"], fragment: "inspection" },
    { name: "International Student Induction", iconUrl: "international-induction-icon.png", redirectArray: ["/international-student-induction"], fragment: "" },
    { name: "Room Inventory", iconUrl: "inventory-icon.png", redirectArray: ["/student-communication"], fragment: "inventory" },
    { name: "Library Induction", iconUrl: "library-induction-icon.png", redirectArray: ["/library-induction"], fragment: "" },
    { name: "Parcels", iconUrl: "parcels-icon.png", redirectArray: ["/parcels"], fragment: "" },
    { name: "Quick Portal", iconUrl: "quick-portal-icon.png", redirectArray: ["/student-communication"], fragment: "quickportal" },
    { name: "Student Induction", iconUrl: "student-induction-icon.png", redirectArray: ["/student-communication"], fragment: "inductionprogrammes" },
    { name: "Living Induction", iconUrl: "student-living-induction-icon.png", redirectArray: ["/student-communication"], fragment: "inductionprogrammes" },
    { name: "Student skills self-assessment", iconUrl: "student-skills-icon.png", redirectArray: ["/skills-self-assessment"], fragment: "" },
    { name: "UNI-FI", iconUrl: "unifi-icon.png", redirectArray: ["/student-communication"], fragment: "unifi" }
  ]

}
