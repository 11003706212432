<div class="wrapper">
    <div class="content-wrapper">
        <div class="text-wrapper">

            <a href="" [routerLink]="['/']">
                <p class="past-breadcrumb">Home</p>
            </a>
            <p class="breadcrumb-arrow past-breadcrumb"> > </p>

            <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index;">
                <p class="breadcrumb-arrow" *ngIf="i > 0"> > </p>
                <a href="" [routerLink]="breadcrumb.link">
                    <p class="breadcrumb" [ngClass]="{ 'past-breadcrumb': i < (breadcrumbs.length - 1) }">{{ breadcrumb.name }}</p>
                </a>
            </ng-container>


        </div>
    </div>
</div>