import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { BespokeComponent } from './pages/bespoke/bespoke.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { HospitalityComponent } from './pages/hospitality/hospitality.component';
import { CorporateComponent } from './pages/corporate/corporate.component';
import { ConstructionComponent } from './pages/construction/construction.component';
import { EducationComponent } from './pages/education/education.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyGdprComponent } from './pages/privacy-gdpr/privacy-gdpr.component';
import { AvailabilityBookerComponent } from './pages/tools/availability-booker/availability-booker.component';
import { OrangeCommunicationsPortalComponent } from './pages/tools/orange-communications-portal/orange-communications-portal.component';
import { JuiceComponent } from './pages/tools/juice/juice.component';
import { ChangecomComponent } from './pages/tools/changecom/changecom.component';
import { ThreeSixtyFeedbackComponent } from './pages/tools/three-sixty-feedback/three-sixty-feedback.component';
import { TopBarComponent } from './components/header/top-bar/top-bar.component';
import { HeaderNavigationComponent } from './components/header/header-navigation/header-navigation.component';
import { HeaderTitleComponent } from './components/header/header-title/header-title.component';
import { HeaderBreadcrumbsComponent } from './components/header/header-breadcrumbs/header-breadcrumbs.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeHeaderBannerComponent } from './components/home-content/home-header/home-header-banner/home-header-banner.component';
import { HomeHeaderComponent } from './components/home-content/home-header/home-header.component';
import { OrangeTechnologyComponent } from './components/home-content/orange-technology/orange-technology.component';
import { FindOutMoreSquareComponent } from './components/home-content/find-out-more-square/find-out-more-square.component';
import { CaseStudiesOverviewComponent } from './components/home-content/case-studies-overview/case-studies-overview.component';
import { OurToolsComponent } from './components/home-content/our-tools/our-tools.component';
import { HorizontalSliderComponent } from './components/horizontal-slider/horizontal-slider.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterLinksComponent } from './components/footer/footer-links/footer-links.component';
import { FooterBottomBarComponent } from './components/footer/footer-bottom-bar/footer-bottom-bar.component';
import { CaseStudyLinkComponent } from './components/home-content/case-study-link/case-study-link.component';
import { ToolsComponent } from './pages/tools/tools/tools.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { ProductComponent } from './components/product/product.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { ExamplesComponent } from './components/examples/examples.component';
import { WhyChooseUsCardComponent } from './components/why-choose-us-card/why-choose-us-card.component';
import { PricePackageComponent } from './components/price-package/price-package.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { EmployeeRelationshipGatewayComponent } from './pages/tools/employee-relationship-gateway/employee-relationship-gateway.component';
import { CleaningManagementAndMonitoringComponent } from './pages/tools/cleaning-management-and-monitoring/cleaning-management-and-monitoring.component';
import { BenchmarkingComponent } from './pages/tools/benchmarking/benchmarking.component';
import { HalfImageComponent } from './components/half-image/half-image.component';
import { BusinessSimulationsComponent } from './pages/tools/business-simulations/business-simulations.component';
import { CookiesPopupComponent } from './components/cookies-popup/cookies-popup.component';
import { StudentCommunicationComponent } from './pages/student-communication/student-communication.component';
import { StudentFacilitiesComponent } from './pages/student-facilities/student-facilities.component';
import { EmployeeGatewayComponent } from './pages/employee-gateway/employee-gateway.component';
import { InductionBenefitsComponent } from './pages/induction-benefits/induction-benefits.component';
import { InductionFunctionalityComponent } from './pages/induction-functionality/induction-functionality.component';
import { TechnologyComponent } from './pages/technology/technology.component';
import { FacultyInductionComponent } from './pages/faculty-induction/faculty-induction.component';
import { LibraryInductionComponent } from './pages/library-induction/library-induction.component';
import { InternationalStudentInductionComponent } from './pages/international-student-induction/international-student-induction.component';
import { SkillsSelfAssessmentComponent } from './pages/skills-self-assessment/skills-self-assessment.component';
import { DamagesComponent } from './pages/damages/damages.component';
import { ParcelsComponent } from './pages/parcels/parcels.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    HowItWorksComponent,
    BespokeComponent,
    CaseStudiesComponent,
    HospitalityComponent,
    CorporateComponent,
    ConstructionComponent,
    EducationComponent,
    TermsAndConditionsComponent,
    PrivacyGdprComponent,
    AvailabilityBookerComponent,
    OrangeCommunicationsPortalComponent,
    JuiceComponent,
    ChangecomComponent,
    ThreeSixtyFeedbackComponent,
    TopBarComponent,
    HeaderNavigationComponent,
    HeaderTitleComponent,
    HeaderBreadcrumbsComponent,
    HeaderComponent,
    HomeHeaderBannerComponent,
    HomeHeaderComponent,
    OrangeTechnologyComponent,
    FindOutMoreSquareComponent,
    CaseStudiesOverviewComponent,
    OurToolsComponent,
    HorizontalSliderComponent,
    ContactUsComponent,
    FooterComponent,
    FooterLinksComponent,
    FooterBottomBarComponent,
    CaseStudyLinkComponent,
    ToolsComponent,
    CaseStudyComponent,
    ProductComponent,
    SlideshowComponent,
    ExamplesComponent,
    WhyChooseUsCardComponent,
    PricePackageComponent,
    CookiesComponent,
    EmployeeRelationshipGatewayComponent,
    CleaningManagementAndMonitoringComponent,
    BenchmarkingComponent,
    HalfImageComponent,
    BusinessSimulationsComponent,
    CookiesPopupComponent,
    StudentCommunicationComponent,
    StudentFacilitiesComponent,
    EmployeeGatewayComponent,
    InductionBenefitsComponent,
    InductionFunctionalityComponent,
    TechnologyComponent,
    FacultyInductionComponent,
    LibraryInductionComponent,
    InternationalStudentInductionComponent,
    SkillsSelfAssessmentComponent,
    DamagesComponent,
    ParcelsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
