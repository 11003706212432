<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">ChangeCom</h1>
    <p>
        ChangeCom, communicating change to large groups in a reportable and interactive way.
    </p>
    <p>
        A new off the shelf service for communicating details of major organisational change to large groups of people. It is a hosted service
        which allows users to access it via the internet from any location and is available for whatever period is required. There is no
        commitment to hardware, software licences or hosting. Some key benefits are:
    </p>
    <ul>
        <li>Allows simultaneous communication with large numbers of people</li>
        <li>Ensures consistency of message across the organisation</li>
        <li>Information can be personalised to groups or even individuals</li>
        <li>Confidentiality prior to release is ensured by production taking place away from the organisation</li>
        <li>Links to other web page can be inserted into any communication page</li>
        <li>Content can be structured in modules and topics</li>
    </ul>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>