<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Business Simulations</h1>
    <p>
        We have extensive experience in developing simulations for Management Training. 
    </p>
    <p>
        Our simulation HOTS is an off the shelf training simulation that, although set in the hospitality industry, can be effectively used by any service-based organisation. We can run training programmes on your behalf using HOTS or we can licence the software with a tutor training package. Details of HOTS <a href="" fragment="hots" [routerLink]="['/hospitality']">here</a>.
    </p>
    <p>
        We have built other complex simulations such as Eurocar which enables teams to run car manufacturing businesses across 5 countries in Europe and Sesamee, a lower-level business start-up simulation set within the SME arena.
    </p>
    <p>
        Simulation can also be used for operational planning as this small demo programme shows.
    </p>
    <div style="padding: 30px 0;">
        <iframe style="width: 100%; height: 1100px; border: 0; outline: none; display: block; margin: auto;" src="https://retsim.nethots.co.uk/SimulationD.aspx"></iframe>
    </div>
    <p>
        Eurocar and Sesamee have all been used by Universities in the UK and Ireland and HOTS has been used by Universities, Hotel Schools and Corporations around the world.
    </p>
</div>

<div class="spacing-element" style="height: 150px;"></div>

<app-footer></app-footer>