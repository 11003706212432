<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Employee Relationship Gateway</h1>
    <p style="text-align: center; display: block;">
        A fully integrated comprehensive employee communication package available in modular form
    </p>
</div>

<app-slideshow [height]="(getScreenWidth() > 650) ? '500px': '450px'" backgroundColour="transparent" [zoom]="false" [autoplay]="false">

    <div class="slideshow-item">
        <div class="presentation-slide" style="background-image: url(/assets/new-images/rgl1.jpg);"></div>
    </div>
    <div class="slideshow-item">
        <div class="presentation-slide" style="background-image: url(/assets/new-images/rgl2.jpg);"></div>
    </div>
    <div class="slideshow-item">
        <div class="presentation-slide" style="background-image: url(/assets/new-images/rgl3.jpg);"></div>
    </div>
    <div class="slideshow-item">
        <div class="presentation-slide" style="background-image: url(/assets/new-images/rgl4.jpg);"></div>
    </div>

</app-slideshow>

<div class="spacing-element" style="height: 150px;"></div>

<app-footer></app-footer>