import { Component, Input, OnInit } from '@angular/core';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from 'src/app/services/case-studies.service';

@Component({
  selector: 'app-examples',
  templateUrl: './examples.component.html',
  styleUrls: ['./examples.component.scss']
})
export class ExamplesComponent implements OnInit {

  @Input() caseStudyType: string;

  constructor(private caseStudyService: CaseStudiesService) { }

  ngOnInit(): void {
    this.caseStudyService.caseStudies.forEach(caseStudy => {
      if (caseStudy.caseStudyType == this.caseStudyType) this.displayedCaseStudies.push(caseStudy);
    });
  }

  displayedCaseStudies: CaseStudy[] = [];

}
