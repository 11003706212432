import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-induction-benefits',
  templateUrl: './induction-benefits.component.html',
  styleUrls: ['./induction-benefits.component.scss']
})
export class InductionBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  getScreenWidth(): number {
    return window.innerWidth;
  }




}
