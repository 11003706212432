<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <div class="row">
        <div class="col-lg-6">
            <h1 class="title">360 Feedback</h1>
            <p>
                Gather feedback and measurement for team, service area or department level. Implement to support full individual 360°
                feedback as a standalone activity or as an integrated part of your performance management approach.
            </p>
            <p>
                Provided as a web based hosted service using your own competencies which you can update at any time, delivering clear
                and easy to interpret reports this automated system requires minimal administration input. It can be used with or without
                facilitated learner support.
            </p>
        </div>
        <div class="col-lg-6">
            <img src="/assets/images/employeefeedback.png" class="feedback-image" />
        </div>
    </div>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>