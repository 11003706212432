import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library-induction',
  templateUrl: './library-induction.component.html',
  styleUrls: ['./library-induction.component.scss']
})
export class LibraryInductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
