import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-home-header-banner',
  templateUrl: './home-header-banner.component.html',
  styleUrls: ['./home-header-banner.component.scss']
})
export class HomeHeaderBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  findOutMore(): void {
    $([document.documentElement, document.body]).animate({
      scrollTop: $(".title").offset().top - 20
    }, 600)
  }

}
