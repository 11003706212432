<app-header
    title="STEMS technology Ltd"
    subtitle="Over 1,000,000 students have been introduced to their university using STEMS"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">The Orange Group</h1>
    <p>
        STEMS is a member of The Orange Group Ltd which was founded over 25years ago by Peter Lucas who is the Managing Director of the Group and of STEMS Technology Ltd. There are three operating companies within the Group.
    </p>
</div>
<div class="companies">
    <div class="companies-inner">
        <div class="row">
            <div class="col-lg-4">
                <div class="company-wrapper">
                    <h1 class="title">STEMS Technology Ltd</h1>
                    <p>
                        Provides products and services to the Higher Education sector.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="company-wrapper">
                    <h1 class="title">Orange Technology Ltd</h1>
                    <p>
                        Primarily serving Corporate clients and providing technology services to the other Group Companies.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="company-wrapper">
                    <h1 class="title" style="font-size: 1.3rem; line-height: 160%;">The Orange Simulation Company Ltd</h1>
                    <p>
                        A joint venture with the 'Russell Partnership Technology Ltd', manages the development and delivery of the HOTS
                        Business Simulation. HOTS is used by Universities, Hospitality Schools and Corporate Hotel Operators.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content-wrapper content-min-height">
    <p>
        We are a small agile team, keen to develop and deliver services which solve important problems for our clients. Innovation has always driven our developments, The Orange Group launched with the first real time, real data business situation for management training. STEMS launched the first online induction programme for students using our web based (SAAS) induction platform in 2007. Since then, we have pioneered other industry leading solutions for student facility management at the same time always delivering exceptionally high levels of customer service.
    </p>
    <h1 class="title" style="margin: 40px 0 20px 0; text-align: left; width: 270px;">Core team members</h1>
    <div class="row">
        <div class="col-sm-5">
            <p><b>Peter Lucas: </b>Managing Director</p>
            <p><b>Chris Orme: </b>Operations Manager</p>
            <p><b>Cherrelle Wallace: </b>Finance Manager</p>
        </div>
        <div class="col-sm-7">
            <p></p>
            <p><b>Miles Doubleday: </b>Development Lead – Communication Platform</p>
            <p><b>Stephen Lee: </b>Development Lead Simulations</p>
        </div>
    </div>
    <h1 class="title">Profiles</h1>
    <p style="margin-top: 30px;"><b>Peter Lucas - Managing Director</b></p>
    <p>
        Peter leads the business and specialises in using technology to develop innovative solutions to strategic issues. His approach of providing the highest levels of customer service combined with economic operational solutions has led him to work extensively across both the public and private sectors. Twenty years in the food industry with Grand Metropolitan and Allied Lyons, followed by 15 years as a management consultant, have provided the experience on which Peter draws.
    </p>
    <p>
        In the Public sector, he has been a contributor to the UK premier technology transfer scheme, Knowledge Transfer Partnerships and has worked as a consultant to central government departments and local authorities. In the private sector he has provided solutions to: British Airways, Hewlett Packard, British Telecom, United Distillers, Granada Group, NPower, AEA, Price Waterhouse Coopers, KPMG, EY, Delaware North and Ascot Racecourse. He has worked on software for Higher Education in various forms for over 25 years
    </p>
    <p>
        Peter is a Fellow of the Chartered Institute of Marketing.
    </p>
    <p style="margin-top: 30px;"><b>Chris Orme - Operations Manager</b></p>
    <p>
        Chris graduated from the University of Plymouth in 2008 and has worked for STEMS since 2010. He manages all operations covering both platform development, hosting and the delivery of services. He is the first technical point of contact for all projects. He has an extensive background in platform development, website design and development, database integration and system compatibility.
    </p>
    <p style="margin-top: 30px;"><b>Cherrelle Wallace - Finance Manager</b></p>
    <p>
        Cherrelle graduated from Bournemouth University with a BA Hons in Accounting & Finance, and then went on to obtain the Advanced Diploma in Management Accounting (CIMA Adv Dip MA).
    </p>
    <p>
        Cherrelle oversees the financial operations of STEMS together with all the other companies in the Orange Group, providing tight control of cash management and timely and relevant analysis of operational costs. She has been with the Group for 9 years and also manages the administration function, is the company data protection officer and is responsible for Cyber Security accreditation.
    </p>
    <!-- <p style="margin-top: 30px;"><b>Karen Starr - Account Manager & Project Manager</b></p>
    <p>
        Karen graduated with a BSc in French and Psychology. Before joining the company, she worked for Research Machines as a Project Manager and Systems Integrated Software Engineer. Karen has managed the development of our online student induction platform since 2006 progressively improving the university specific induction functionality. Karen has also overseen the development of Uni - Fi and RAVI, our Resilience and Vulnerability experimental project. As an Account Manager she has worked 16 UK universities since 2007
    </p> -->
    <p style="margin-top: 30px;"><b>Miles Doubleday – Induction Platform Developer</b></p>
    <p>
        Miles graduated from Pembroke College Oxford with a BA in Maths. He began the development of the Orange Induction platform in 2003 when he already had over 25v ears experience in software development. Initially the platform was used on Intranets and Miles masterminded the transition to the web to enable Orange, in 2006, to provide a hosted service for induction programmes. Since that time, he has overseen a programme of continuous development of functionality and of refreshment of technologies utilised ensuring the platform has always remained ahead of competitors and has linked seamlessly with other web-based applications.
    </p>
    <p style="margin-top: 30px;"><b>Stephen Lee - Simulation Lead</b></p>
    <p>
        Stephen graduated from Merton College, Oxford with a Class I (Hons) degree in Mathematics. He went on to earn his D. Phil. At Oxford in Mathematical Quantum Scattering Theory. Stephen has skills in simulation engine design, database structures, network capabilities, programming in C++, Delphi and many other languages. Stephen has designed and developed three major simulations. He has continued to develop HOTS our Hotel Operation simulation as the needs of the industry have changed over a period of 20 years. HOTS is used by 80 Universities and Hotel Schools around the world. Corporate clients such as Hilton and Marriott use it for training worldwide.
    </p>
</div>


<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>