<app-header
    title="Damages"
    subtitle=""
></app-header>

<div class="spacing-element"></div>
<app-slideshow [height]="(getScreenWidth() > 650) ? '400px': '250px'" display="block" [width]="(getScreenWidth() > 650) ? '600px': '400px'" [zoom]="false" [autoplay]="false" zoomDirection="out">

    <div class="slideshow-item">
        <img class="slide-background" src="/assets/stems-images/DamagesBenefits.png" />
    </div>
    <div class="slideshow-item">
        <img class="slide-background" src="/assets/stems-images/DamagesFeatures.png" />
    </div>

</app-slideshow>
<div class="spacing-element"></div>

<app-footer></app-footer>