<div class="wrapper">
    <div class="content-wrapper">
        <div class="row">

            <div class="col-lg-4">
                <h6>STEMS Technology Ltd</h6>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/about-us']"><p class="footer-link-text">About us</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/how-it-works']"><p class="footer-link-text">How it works</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/case-studies']"><p class="footer-link-text">Case Studies</p></a>
                </div>
		<div style="margin-top: 20px;">
			<a href="https://orangetechnology.co.uk/"><p class="footer-link-text">Part of <img src="assets/css-images/orange-tech-logo.png" alt="Orange Technology Ltd" style="margin-left: 5px; width: 50%;" /></p></a>
		</div>
            </div>

            <div class="col-lg-4">
                <h6>Sectors</h6>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/student-communication']"><p class="footer-link-text">Student Communication</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/student-facilities']"><p class="footer-link-text">INROOM Student Facilities</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/employee-gateway']"><p class="footer-link-text">Employee Gateway Assistance with recruitment, onboarding, induction & compliance</p></a>
                </div>
                <!--<div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/education']"><p class="footer-link-text">Education</p></a>
                </div>-->
            </div>

            <div class="col-lg-4">
                <h6>Contact us</h6>
                <div><p>STEMS Technology Ltd</p></div>
                <div><p>Basepoint</p></div>
                <div><p>Aviation Business Park</p></div>
                <div><p>Christchurch</p></div>
                <div><p>Dorset, BH23 6NX</p></div>
                <div><p>info@stems.org.uk</p></div>
            </div>
        </div>
    </div>
</div>