import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-gdpr',
  templateUrl: './privacy-gdpr.component.html',
  styleUrls: ['./privacy-gdpr.component.scss']
})
export class PrivacyGdprComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
