<app-header
    title="Cookies"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height">
    <p class="title">Cookies Policy</p>
    <p>
        In the operation of this website we sometimes place small amounts of information on your device, for example, computer or mobile phone.
        These include files known as cookies. They cannot be used to identify you personally but they do assist in your use of the site. By using
        this website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
    </p>
    <p class="subheading">Examples of how cookies improve services offered to you:</p>
    <ul>
        <li>
            enabling part of the site to recognise your device so you don't have to repeat information you have already given us.
        </li>
        <li>
            recognising that you may already have given a username and password and then taking you to an appropriate page.
        </li>
        <li>
            measuring how many people are using services to enable us to maintain them.
        </li>
    </ul>
    <p class="subheading">We use two types of cookies used on our website:</p>
    <ul>
        <li>
            Session cookies are temporary cookies that remain in the cookie file of your browser until you close the browser. We use session cookies
            to carry information across pages of our site and avoid you having to re-enter information.
        </li>
        <li>
            Persistent cookies remain in the cookie file of your browser even after the browser is closed. The length of time a cookie remains depends
            on its lifespan. We use persistent cookies to collect and compile anonymous, aggregated information for statistical and evaluation purposes
            to help us understand how users use the website and help us improve the structure of our website.
        </li>
    </ul>
    <p class="subheading">Google Analytics </p>
    <p>
        We use Google Analytics to analyse the use of this website. Google Analytics sets cookies to help us accurately estimate the number of
        visitors to the website and volumes of usage, e.g. website statistics. 
    </p>
    <p>
        For further details on the cookies set by Google Analytics, please refer to the Google Code website.
    </p>
    <p class="subheading">Refusing cookies</p>
    <p>
        Most browsers allow you to refuse to accept cookies, blocking cookies may have a negative impact upon the usability of some websites.
    </p>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>