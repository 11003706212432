<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Availability Booker</h1>
    <p>
        Enhance performance by using a streamlined service in which your workforce input their availability into a bespoke online booking system,
        where shifts or sessions can be allocated effectively and efficiently.
    </p>
    <p>
        BikeBook is a good example of a bespoke online booking system, used to manage Cycle Training. Trainers and Examiners put details of their
        availability into a personalised online calendar. Schools or other organisations requiring cycle training login through their personalised
        credentials and book course or tests on days when there are suitable trainers and testers available.
    </p>
</div>

<div class="spacing-element" style="height: 150px;"></div>

<app-footer></app-footer>