import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-availability-booker',
  templateUrl: './availability-booker.component.html',
  styleUrls: ['./availability-booker.component.scss']
})
export class AvailabilityBookerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
