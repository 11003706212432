<div class="wrapper">
    <div class="content-wrapper">
        <img src="/assets/stems-images/logos/Colour/STEMS-logo-2020-colour.png" [routerLink]="['/']" class="logo" />
        <div class="nav-wrapper">
            <a href="" [routerLink]="['/student-communication']" class="nav-anchor">
                <p class="nav-link">Student Communication</p>
            </a>
            <a href="" [routerLink]="['/student-facilities']" class="nav-anchor">
                <p class="nav-link">Student Facilities</p>
            </a>
            <a href="" [routerLink]="['/employee-gateway']" class="nav-anchor">
                <p class="nav-link">Employee Gateway</p>
            </a>
        </div>
        <div class="nav-wrapper-mobile">
            <div class="nav-mobile-icon" (click)="openMobileDropdown()">
                <div class="nav-mobile-icon-line"></div>
                <div class="nav-mobile-icon-line"></div>
                <div class="nav-mobile-icon-line"></div>
            </div>
            <div class="mobile-dropdown nav-dropdown">
                <a href="" [routerLink]="['/student-communication']" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Student Communication</p>
                </a>
                <a href="" [routerLink]="['/student-communication']" fragment="hots" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Student Facilities</p>
                </a>
                <a href="" [routerLink]="['/employee-gateway']" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Employee Gateway</p>
                </a>
            </div>
        </div>
    </div>
</div>