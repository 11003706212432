<div class="wrapper">
    <div class="content-wrapper">
        <a href="" [routerLink]="['/']" class="nav-anchor">
            <p class="nav-link">Home</p>
        </a>
        <a href="" [routerLink]="['/about-us']" class="nav-anchor">
            <p class="nav-link">About Us</p>
        </a>
        <a href="" [routerLink]="['/how-it-works']" class="nav-anchor">
            <p class="nav-link">How it Works</p>
        </a>
        <a href="" [routerLink]="['/case-studies']" class="nav-anchor">
            <p class="nav-link no-right-border">Case Studies</p>
        </a>
    </div>
</div>