import { Component, OnInit } from '@angular/core';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from 'src/app/services/case-studies.service';

@Component({
  selector: 'app-case-studies-overview',
  templateUrl: './case-studies-overview.component.html',
  styleUrls: ['./case-studies-overview.component.scss']
})
export class CaseStudiesOverviewComponent implements OnInit {

  constructor(private caseStudyService: CaseStudiesService) { }

  ngOnInit(): void {
    for (let i=0; i < 3; i++) {
      this.caseStudies.push(this.caseStudyService.caseStudies[i]);
    }
  }

  caseStudies: CaseStudy[] = [];

}
