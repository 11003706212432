<app-header
    title="Privacy and GDPR"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height">
    <p><p class="title">Our Privacy Policy</p>
    <p>
        At STEMS Technology Ltd (“We”) your privacy is important to us and We are committed to protecting and respecting your privacy. This privacy statement
        provides information about the personal information that We collect, and the ways in which We use that personal information. Please read this privacy
        policy carefully to understand our policies regarding your personal data. By visiting the website, you are accepting and consenting to the practices
        described in this policy together with our “Terms & Conditions”.
    </p>
    <p>
        The Data Controller for this web site is STEMS Technology Ltd. The purpose of this statement is to inform users of the website, about the information
        that is collected from them when they visit this site, how this information is used, if it is disclosed and the ways in which We protect users' privacy.
    </p>
    <p>
        The General Data Protection Regulation (EU) 2016/679 establishes rights for individuals whose personal data is processed by a third party and protects
        and empowers all EU citizens data privacy. Any organisation which processes information about living individuals is a data controller for the purposes
        of the above Act and Regulation. STEMS Technology Ltd, as data controller, is required by the Act to ensure that individual data subjects are aware
        of how their personal data may be used, the purpose/purposes for processing that data and to whom, if anybody, that data may be disclosed.
    </p>
    <p>
        We may collect and use the following kinds of personal information:
    </p>
    <ul>
        <li>
            information about your use of this website;
        </li>
        <li>
            information that you provide us for the purpose of registering with the website or contacting us through the website
        </li>
        <li>
            information that we receive from third parties (for example; business partners) 
        </li>
        <li>
            information that you provide for the purpose of subscribing to our newsletters.
        </li>
        <li>
            any other information that you send/give us by phone, e-mail or otherwise.
        </li>
    </ul>
    <p>We may use your personal information to:</p>
    <ul>
        <li>administer this website</li>
        <li>personalise the website for you</li>
        <li>help keep our website safe and secure</li>
        <li>carry out our obligations arising from any agreements entered between you and us</li>
        <li>provide you with the information, products and services that you request from us</li>
        <li>provide you with information about other products and services we offer that are similar to those that you have already purchased or enquired about</li>
        <li>enable your access to and use of the website services</li>
        <li>notify you about changes to our service</li>
        <li>send you marketing communications</li>
    </ul>
    <p>
    <p>
        You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing
        purposes, please <a href="mailto:cherrelle.wallace@theorangegroup.com?subject=Unsubscribe">click here</a>.
    </p>
    <p>
        We may share your personal information with any member of our group, which means our ultimate holding company and its subsidiaries, as
        defined in section 1159 of the UK Companies Act 2006.
    </p>
    <p>
        Where We disclose your personal information to agents or sub-contractors for these purposes, the agent or sub-contractor in question will
        be obligated to use that personal information in accordance with the terms of this privacy statement.
    </p>
    <p>
        In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, We may disclose your personal information
        to the extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings, and in order 
        to establish, exercise or defend its legal rights.
    </p>
    <p class="subtitle"><b>Securing your data</b></p>
    <p>
        We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.
    </p>
    <p>
        We will store all the personal information you provide on our secure servers. Information relating to electronic transactions entered into
        via this website will be protected by encryption technology.
    </p>
    <p>
        Unfortunately, the transmission of information via the internet is not completely secure. Although We do our best to protect your personal
        data, We cannot guarantee the security of your data submitted to our site and any data submitted is done so at your own risk.
    </p>
    <p class="subtitle"><b>Cross-border data transfers</b></p>
    <p>
        Information that We collect may be stored and processed in and transferred between the UK and any the countries. It will never be stored outside the EU.
    </p>
    <p>
        However, personal information that you submit for publication on the website will be published on the internet and may therefore be available around the world.
    </p>
    <p>
        You agree to such cross-border transfers of personal information where we have complied with this privacy policy.
    </p>
    <p class="subtitle"><b>Data Retention</b></p>
    <p>
        We keep information only for so long as it is needed for the efficient and effective delivery or fulfilment of the software, products, or services using or
        contemplating using by the Data Subject and for a reasonable time thereafter, or to meet any legal requirements. We will either destroy or remove information
        when it is no longer needed.
    </p>
    <p class="subtitle"><b>Updating this statement</b></p>
    <p>
        We may update this privacy policy by posting a new version on this website.
    </p>
    <p class="subtitle"><b>Your Rights</b></p>
    <p>The GDPR gives you rights over how your personal information is used:</p>
    <ul>
        <li>The right to be informed - we must tell you how we process your personal information.</li>
        <li>The right of access - you can ask to see what personal information we hold about you. This is called a Subject Access Request (SAR).</li>
        <li>The right of rectification - where information about you is inaccurate, you can ask us to correct it.</li>
        <li>The right to erasure – in some circumstances, or where we have no compelling reason to retain your personal information, you can request deletion of that information.</li>
        <li>The right to restrict processing – in some circumstances, you can ask us to restrict the processing of your personal data. This right, where it applies, also allows you to ask us to retain your personal information but not to use it.</li>
        <li>The right to data portability – in some circumstances, you can request a copy of the personal data you have provided to us in a machine-readable form, so you can transfer it to another organisation for a similar purpose.</li>
        <li>Right to object – where there is no legal obligation for us to process your data, you can object to us processing your personal information.</li>
        <li>Rights in relation to automated decisions and profiling - where computers make decisions about you, including automated profiling, you have a right to challenge the decision or ask for a human to check an automated decision.</li>
    </ul>
    <p>To discuss any of these rights, please contact us and let us know how we can help.</p>
    <p>Any access request to this information may be subject to a fee to meet our costs in providing you with the information We hold.</p>
    <p class="subtitle"><b>Other websites</b></p>
    <p>This website contains links to other websites. We are not responsible for the privacy policies or practices of any third party.</p>
    <p>We use HubSpot to assist us in gathering data regarding the use of our website. The Hot Jar data retention policy can be found</p>
    <p class="subtitle"><b>Data Protection Officer</b></p>
    <p>The Data Protection Officer (DPO) within STEMS Technology Ltd is who can be contacted via:</p>
    <ul>
        <li>
            By email to info@stems.org.uk
        </li>
        <li>
            By post to: The Data Protection Officer, STEMS Technology Limited, Basepoint, Aviation Business Park, Christchurch, Dorset, BH23 6NX
        </li>
        <li>
            call us on 0845 505 3577
        </li>
    </ul>

</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>