import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CaseStudy } from 'src/app/models/case-study.class';
import { CaseStudiesService } from 'src/app/services/case-studies.service';

@Component({
  selector: 'app-employee-gateway',
  templateUrl: './employee-gateway.component.html',
  styleUrls: ['./employee-gateway.component.scss']
})
export class EmployeeGatewayComponent implements OnInit {

  constructor(private elem: ElementRef, private caseStudyService: CaseStudiesService, private router: Router) {
    window.onresize = this.setScreenWidth.bind(this);
  }

  ngOnInit(): void {
    this.setScreenWidth();
  }

  displayedCaseStudies: CaseStudy[] = [];

  ngAfterViewInit(): void {
    this.slideTextWrappers = this.elem.nativeElement.querySelectorAll('.slide-text-wrapper');
  }

  screenWidth: number;
  slideHandle: number = null;
  slideTextWrappers: any[] = [];

  setScreenWidth(): void {
    this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  slideChanged(index: number) {
    clearTimeout(this.slideHandle);
    this.slideHandle = setTimeout(this.showText.bind(this), 1000, index);
  }

  showText(index: number): void {
    this.slideTextWrappers.forEach(ele => ele.style.display = "none");
    $(this.slideTextWrappers[index]).slideDown(1000);
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

  // This is used to repeat the elements in the slider
  sliderLoopArray: number[] = Array(8).fill(0);

  mouseDownX: number = null;

  caseStudyClicked(event: any, linkArray: string[], mouseDown: boolean): void {
    if (mouseDown) {
      this.mouseDownX = event.pageX;
    } else {
      let dragDistance = Math.abs(this.mouseDownX - event.pageX);
      if (dragDistance <= 10) this.router.navigate(linkArray);
    }
  }

}
