<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <div class="row">
        <div class="col-lg-6">
            <h1 class="title">Cleaning Management and Monitoring</h1>
            <p>
                Clapp our Cleaning App enables you to formalise and manage cleaning routines and professionally communicate your cleaning routine to
                customers or visitors when they scan your location QR code or visit a reception desk or bar. It also discreetly informs team members
                when cleaning is due, reminds them of the procedure if necessary, allows them to confirm online that cleaning has been completed and
                allows you up to the minute access of all cleaning routines in all locations.
            </p>
        </div>
        <div class="col-lg-6">
            <img src="/assets/new-images/cleaningImage.jpg" class="feedback-image" />
        </div>
    </div>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>