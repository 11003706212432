import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-damages',
  templateUrl: './damages.component.html',
  styleUrls: ['./damages.component.scss']
})
export class DamagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

}
