<div class="wrapper">
    <div class="content-wrapper">
        <h1 class="title">Case Studies</h1>
        <div class="link-wrapper">
            <ng-container *ngFor="let study of caseStudies;">
                <a href="" [routerLink]="['/case-study', study.reference]"><app-case-study-link
                    [imageUrl]="study.clientLogoUrl"
                    [content]="study.title"
                ></app-case-study-link></a>
            </ng-container>
        </div>
        <div class="centering">
            <button class="button3" href="" [routerLink]="['/case-studies']">Read All</button>
        </div>
    </div>
</div>