import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parcels',
  templateUrl: './parcels.component.html',
  styleUrls: ['./parcels.component.scss']
})
export class ParcelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

}
