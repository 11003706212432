import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills-self-assessment',
  templateUrl: './skills-self-assessment.component.html',
  styleUrls: ['./skills-self-assessment.component.scss']
})
export class SkillsSelfAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
