<div class="wrapper">
    <img [src]="imageUrl" />
    <h3>{{ title }}</h3>
    <p>{{ content }}</p>
    <span *ngIf="fragment">
        <button *ngIf="button && firefoxDisplay" class="button2 firefoxButton" [routerLink]="linkArray" [fragment]="fragment" href="">FIND OUT MORE</button>
        <button *ngIf="button && !firefoxDisplay" class="button2" [routerLink]="linkArray" [fragment]="fragment" href="">FIND OUT MORE</button>
    </span>
    <span *ngIf="!fragment">
        <button *ngIf="button && firefoxDisplay" class="button2 firefoxButton" [routerLink]="linkArray" href="">FIND OUT MORE</button>
        <button *ngIf="button && !firefoxDisplay" class="button2" [routerLink]="linkArray" href="">FIND OUT MORE</button>
    </span>
</div>