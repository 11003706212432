import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-relationship-gateway',
  templateUrl: './employee-relationship-gateway.component.html',
  styleUrls: ['./employee-relationship-gateway.component.scss']
})
export class EmployeeRelationshipGatewayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getScreenWidth(): number {
    return window.innerWidth;
  }

}
