<app-header
    title="Terms and Conditions"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height">
    <div class="spacing-element" style="height: 50px"></div>
    <h2 class="title">Our Terms and Conditions</h2>
    <p><strong>Contact us</strong></p>
    <p>If you have any questions or require clarification of the terms and conditions laid out below please:</p>
    <ul>
        <li>Email us at <a href="mailto:info@stems.org.uk">info@stems.org.uk</a></li>
        <li>Or call us on 0845 505 3577</li>
    </ul>
    <p><strong>Introduction</strong></p>
    <p>
        This website is owned and maintained by STEMS Technology Ltd “STEMS”. The use of this website is subject to the following terms and conditions.
        By using this website, you agree to be legally bound by these terms and conditions. If you disagree with these terms and conditions you should
        cease using this website.
    </p>
    <p>
        By using this website and agreeing to these terms and conditions, you consent to STEMS's use of your information in accordance with the terms of
        our Privacy/GDPR Policy in our Privacy section.
    </p>
    <p>
        By using this website and agreeing to these terms and conditions, you consent to STEMS's use
        of cookies in accordance with the terms of our Cookies Policy.
    </p>
    <p>
        The website has been developed by and is maintained by STEMS Technology
        Limited, registered in England and Wales, Company number is 03370028 registered office: Sejant House, 2 The Green, 117 Salisbury Road,
        Christchurch, BH23 7NZ.
    </p>
    <p>
        Our production centre is at Basepoint, Aviation Business Park, Christchurch, BH23 6NX.
    </p>
    <p><strong>Intellectual property of this website</strong></p>
    <p>
        STEMS owns the intellectual property rights in the website and material on the website and reserves all the rights to it. Some material forming part
        of the intellectual property rights is owned by other organisations and it is used under licence in all cases
    </p>
    <p>
        You may view, download and print pages from the website for your own personal use, or for the use of your employer. It may be distributed within your
        organisation subject to the restrictions below.
    </p>
    <p>
        You must not:
    </p>
    <ul>
        <li>Republish externally any material from this website.</li>
        <li>Reproduce or otherwise exploit material on this website for a commercial purpose.</li>
        <li>Redistribute material from this website.</li>
    </ul>
    <p><strong>Acceptable use</strong></p>
    <p>You must not use this website to store or host any malicious computer software including viruses, worms, spyware, trojan horses or any other damaging programmes.</p>
    <p>You must not collect any data automatically or otherwise from this website without STEMS’s express written consent.</p>
    <p>You must not use this website to transmit or send unsolicited commercial communications.</p>
    <p>You must not use this website for any marketing activities without STEMS’s express written consent.</p>
    <p>You must not use this website in any way that is harmful, illegal or fraudulent.</p>
    <p><strong>No warranties</strong></p>
    <p>Whereas every care will be taken in providing and maintaining this website, STEMS does not warrant that:</p>
    <ul>
        <li>The information on this website is complete, true, accurate or non-misleading.</li>
        <li>Use of this web site, or any item obtained from it, will not cause damage to any computer or other property.</li>
        <li>This website will be always be available</li>
    </ul>
    <p><strong>Limitations of liability</strong></p>
    <p>STEMS will not be liable in relation to the contents of, or any use of this website:</p>
    <ul>
        <li>For any direct loss.</li>
        <li>For any indirect, special or consequential loss.</li>
        <li>For any business losses.</li>
    </ul>
    <p>These limitations of liability apply even if STEMS has been expressly advised of the potential loss.</p>
    <p>
        Where the website contains links to other websites provided by third parties, these links are provided for user information only. STEMS has
        no control over the contents of those sites and any resources promoted by them.
    </p>
    <p><strong>Exceptions</strong></p>
    <p>Nothing in this disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.</p>
    <p>Nothing in this disclaimer will exclude or limit STEMS’s liability in respect of any:</p>
    <ul>
        <li>Death or personal injury caused by STEMS’s negligence;</li>
        <li>Fraud or fraudulent misrepresentation on the part of STEMS; or</li>
        <li>Matter which it would be illegal or unlawful for STEMS to exclude or limit.</li>
    </ul>
    <p><strong>Reasonableness</strong></p>
    <p>By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.</p>
    <p>If you do not think they are reasonable, you must cease using this website.</p>
    <p><strong>Other parties</strong></p>
    <p>
        You accept that, as a limited liability entity, STEMS has an interest in limiting the personal liability of its directors and employees. You agree that you
        will not bring any claim personally against STEMS’s directors or employees in respect of any losses you suffer in connection with the website.
    </p>
    <p>
        Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect
        STEMS’s directors, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as STEMS.
    </p>
    <p><strong>Unenforceable provisions</strong></p>
    <p>
        If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the
        other provisions of this website disclaimer.
    </p>
    <p><strong>Indemnity</strong></p>
    <p>
        You hereby indemnify STEMS and undertake to keep STEMS indemnified
        against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by STEMS to a third
        party in settlement of a claim or dispute on the advice of STEMS’s legal advisers) incurred or suffered by STEMS arising out of any breach by you
        of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.
    </p>
    <p><strong>Breaches of these terms and conditions</strong></p>
    <p>Without prejudice to STEMS’s other rights under these terms and conditions, if you breach these terms and conditions in any way, STEMS may take
        such action as STEMS deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing
        the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they
        block your access to the website and/or bringing court proceedings against you.
    </p>
    <p><strong>Variation</strong></p>
    <p>STEMS may revise these terms and conditions from time-to-time. Revised terms and conditions will apply to the use of this website from the date of the
        publication of the revised terms and conditions on this website. Please check this page regularly to ensure you are familiar with the current
        version.
    </p>
    <p>
    STEMS may update our site from time to time, and may change the content at any time. However, the content on our site may be out of date at any given time,
        and we are under no obligation to update it.
    </p>
    <p>
        STEMS does not guarantee that the website, or any content on it, will be free from errors or omissions.
    </p>
    <p><strong>Assignment</strong></p>
    <p>STEMS may transfer, sub-contract or otherwise deal with STEMS’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>
    <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>
    <p><strong>Severability</strong></p>
    <p>
        If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue
        in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest
        of the provision will continue in effect.
    </p>
    <p><strong>Law and jurisdiction</strong></p>
    <p>If you are a business, these terms and conditions will be governed by and construed in accordance with English Law, and any disputes relating to these terms and
        conditions will be subject to the exclusive jurisdiction of the courts of England and Wales.
    </p>
    <p>If you are a consumer, these terms and conditions will be
        governed by and construed in accordance with English Law, and any disputes relating to these terms and conditions will be subject to the non-exclusive
        jurisdiction of the courts of England and Wales.
    </p>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>