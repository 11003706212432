<div class="wrapper">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <p>Copyright @ 2021 STEMS Technology Ltd.</p>
            </div>
            <div class="col-sm-6 right-col">
                <a href="" [routerLink]="['/terms-and-conditions']">Website Terms & Conditions</a>
                <div class="divider"></div>
                <a href="" [routerLink]="['/privacy-gdpr']">Privacy</a>
                <div class="divider"></div>
                <a href="" [routerLink]="['/cookies']">Cookies</a>
            </div>
        </div>
    </div>
</div>