<app-header
    title="Skills Self-Assessment for Students"
    subtitle=""
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Skills Self-Assessment for Students</h1>
    <p>Studying at university is not just about developing your knowledge and understanding of your chosen subject. It is also about developing your own personal, academic and employability skills. This involves having an understanding of your strengths, using these to your best advantage, and recognising areas that you need to develop further. This self-assessment exercise has been designed to help you to: </p>
    <ul>
        <li>Reflect on some of the skills that you already have</li>
        <li>Think about the areas that you may need to work on </li>
        <li>Plan how and when you are going to develop your skills</li>
    </ul>
    <p>You don't have to do the exercise in one go, you can return to it as many times as you like.</p>
    <p>Skills Assessed</p>
    <ul>
        <li>Information Skills</li>
        <li>Digital Literacy</li>
        <li>Verbal Communication</li>
        <li>Academic Writing</li>
        <li>Reading Skills</li>
        <li>Numeracy</li>
        <li>Visual Literacy</li>
        <li>Employability</li>
        <li>Feedback & Evaluation</li>
    </ul>
    <p style="margin-bottom: 30px;">See what students think about the Self-Assessment Exercise by clicking on 'What the students say' on the first page of each module. Also find out what the lecturers say.</p>
    <div class="row">
        <div class="col-md-6">
            <img style="text-align: center; display: block; margin: auto;" src="/assets/stems-images/whatstudentssay.jpg" />
        </div>
        <div class="col-md-6">
            <img style="text-align: center; display: block; margin: auto;" src="/assets/stems-images/whatlecturerssay.jpg" />
        </div>
    </div>
    <p style="margin-top: 30px;">For information on how we could set up skills assessment for you <a href="" [routerLink]="['/student-communication']" fragment="contact">CONTACT US</a>.</p>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>