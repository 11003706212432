import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Pages
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { BespokeComponent } from './pages/bespoke/bespoke.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { EducationComponent } from './pages/education/education.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyGdprComponent } from './pages/privacy-gdpr/privacy-gdpr.component';
import { ThreeSixtyFeedbackComponent } from './pages/tools/three-sixty-feedback/three-sixty-feedback.component';
import { ToolsComponent } from './pages/tools/tools/tools.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { EmployeeRelationshipGatewayComponent } from './pages/tools/employee-relationship-gateway/employee-relationship-gateway.component';
import { CleaningManagementAndMonitoringComponent } from './pages/tools/cleaning-management-and-monitoring/cleaning-management-and-monitoring.component';
import { BenchmarkingComponent } from './pages/tools/benchmarking/benchmarking.component';
import { BusinessSimulationsComponent } from './pages/tools/business-simulations/business-simulations.component';
import { StudentCommunicationComponent } from './pages/student-communication/student-communication.component';
import { StudentFacilitiesComponent } from './pages/student-facilities/student-facilities.component';
import { EmployeeGatewayComponent } from './pages/employee-gateway/employee-gateway.component';
import { InductionBenefitsComponent } from './pages/induction-benefits/induction-benefits.component';
import { InductionFunctionalityComponent } from './pages/induction-functionality/induction-functionality.component';
import { TechnologyComponent } from './pages/technology/technology.component';
import { FacultyInductionComponent } from './pages/faculty-induction/faculty-induction.component';
import { LibraryInductionComponent } from './pages/library-induction/library-induction.component';
import { InternationalStudentInductionComponent } from './pages/international-student-induction/international-student-induction.component';
import { SkillsSelfAssessmentComponent } from './pages/skills-self-assessment/skills-self-assessment.component';
import { DamagesComponent } from './pages/damages/damages.component';
import { ParcelsComponent } from './pages/parcels/parcels.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'bespoke', component: BespokeComponent },
  { path: 'case-studies', component: CaseStudiesComponent },
  { path: 'case-studies/:page', component: CaseStudiesComponent },
  { path: 'case-study/:caseStudyReference', component: CaseStudyComponent },
  { path: 'student-communication', component: StudentCommunicationComponent },
  { path: 'student-facilities', component: StudentFacilitiesComponent },
  { path: 'employee-gateway', component: EmployeeGatewayComponent },
  { path: 'induction-benefits', component: InductionBenefitsComponent },
  { path: 'induction-functionality', component: InductionFunctionalityComponent },
  { path: 'technology', component: TechnologyComponent },
  { path: 'education', component: EducationComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'privacy-gdpr', component: PrivacyGdprComponent },
  { path: 'cleaning-management', component: CleaningManagementAndMonitoringComponent },
  { path: 'faculty-induction', component: FacultyInductionComponent },
  { path: 'library-induction', component: LibraryInductionComponent },
  { path: 'international-student-induction', component: InternationalStudentInductionComponent },
  { path: 'skills-self-assessment', component: SkillsSelfAssessmentComponent },
  { path: 'damages', component: DamagesComponent },
  { path: 'parcels', component: ParcelsComponent },
  /*{ path: 'tools/employee-relationship-gateway', component: EmployeeRelationshipGatewayComponent },
  { path: 'tools/cleaning-management-and-monitoring', component: CleaningManagementAndMonitoringComponent },
  { path: 'tools/benchmarking', component: BenchmarkingComponent },
  { path: 'tools/three-sixty-feedback', component: ThreeSixtyFeedbackComponent },
  { path: 'tools/business-simulations', component: BusinessSimulationsComponent },
  { path: 'tools', component: ToolsComponent },*/
  { path: 'case-study',   redirectTo: '/case-studies', pathMatch: 'full' } // This is required to ensure breadcrumbs work on "case-study/:caseStudyReference" route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
