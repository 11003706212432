<div class="content-wrapper">
    <h1 class="title">STEMS Technology Ltd</h1>
    <h5>Integrated personalised online pre-arrival Induction, targeted ongoing Communication, efficient Room Inventory, Room Inspection, Fault Management, Parcels Management, Damages Management, Cleaning Management create a safe and efficient environment allowing your students to focus on their studies.</h5>
    <div class="centering">
        <app-find-out-more-square
            imageUrl="/assets/new-images/hospitality-icon.png"
            title="Student Communication"
            content=""
            [linkArray]="['/student-communication']"
        ></app-find-out-more-square>
        <app-find-out-more-square
            imageUrl="/assets/new-images/corporate-icon.png"
            title="Student Facilities"
            content=""
            [linkArray]="['/student-communication']"
            fragment="hots"
        ></app-find-out-more-square>
        <app-find-out-more-square
            imageUrl="/assets/new-images/construction-icon.png"
            title="Employee Gateway"
            content=""
            [linkArray]="['/employee-gateway']"
        ></app-find-out-more-square>
    </div>
    <p>
        Our services accompany students throughout their journey with the University starting as soon as they are awarded a place. Pre-arrival induction can cover a great deal of ground and free up time for more face-to-face dialogue on arrival. Smooth operation of room inventory and ongoing fault management and continuing communication all add to the student experience.
    </p>
    <p>
        We are well experienced in delivering complex information to large numbers of people tailored to the individual at a time that is convenient, whilst also gathering information in a level of interactivity that is only available via an online service.
    </p>
</div>