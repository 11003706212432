<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">Benchmarking</h1>
    <p>
        Improve performance by sharing data. Enabling a group of independent organisations to pool data and for each assess their own performance against the average whilst ensuring anonymity of all data contributed. Fully web based totally configurable to your market and to the key indicators you wish to measure. Static and variable data can be specified as well as the ratios and reports you require.
    </p>
    <p>
        To see an example, visit our Bespoke section <a href="" [routerLink]="['/bespoke']">Benchmarking</a>
    </p>
</div>

<div class="spacing-element" style="height: 150px;"></div>

<app-footer></app-footer>