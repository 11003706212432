import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaning-management-and-monitoring',
  templateUrl: './cleaning-management-and-monitoring.component.html',
  styleUrls: ['./cleaning-management-and-monitoring.component.scss']
})
export class CleaningManagementAndMonitoringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
