import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orange-technology',
  templateUrl: './orange-technology.component.html',
  styleUrls: ['./orange-technology.component.scss']
})
export class OrangeTechnologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
